import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import Request from 'util/api.service';

import {FETCH_PORTS} from 'constants/actionTypes';
import {API_URL} from "constants/apiConstants";

import {addPorts} from './actions';

const fetchPortsAsync = async () => {
    return await Request.get(API_URL + 'master/port')
        .then(response => response.data)
        .catch(error => error);
};

function* fetchPortsFromApi() {
    try {
        const port_data = yield call(fetchPortsAsync);
        if (port_data.data.status !== 'failed') {
            yield put(addPorts(port_data.data));
        }
    } catch (error) {
        // catch throw
        console.log('ports fetch error : ', error);
    }
}

export function* watchFetchPorts() {
    yield takeEvery(FETCH_PORTS, fetchPortsFromApi);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchPorts)
    ]);
}