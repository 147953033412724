import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {FETCH_BOOKINGS, REFRESH_BOOKINGS} from "../../constants/actionTypes";

import {addBookings} from "./actions";
import Booking from "../../util/booking.service";

const fetchBookingsAsync = async () => {
    return await Booking.history()
        .then(response => response.data)
        .catch(error => error);
};

function* fetchBookingsFromApi() {
    try {
        const booking_data = yield call(fetchBookingsAsync);
        if (booking_data.data.status !== 'failed') {
            yield put(addBookings(booking_data.data));
        }
    } catch (error) {
        // catch throw
        console.log('booking fetch error : ', error);
    }
}

export function* watchFetchBookings() {
    yield takeEvery(FETCH_BOOKINGS, fetchBookingsFromApi);
}

export function* watchRefreshBookings() {
    yield takeEvery(REFRESH_BOOKINGS, fetchBookingsFromApi);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchBookings),
        fork(watchRefreshBookings)
    ]);
}
