import React, {Component} from "react";
import Select from "react-select";
import {AvForm, AvGroup, AvField} from "availity-reactstrap-validation";
import {Table, Card, CardBody, Button, Collapse} from "reactstrap";
import {
    DRAFT,
    USER_APPROVAL_REQUEST,
    USER_APPROVED,
    USER_REJECTED,
    SEND_TO_VENDOR,
    RETURN_FOR_CORRECTION,
    REVISE_FIRST_PRINT, blStatus
} from "../../../constants/blStatus";
import Search from "../../../util/search.service";
import Request from "../../../util/api.service";
import {API_URL} from "../../../constants/apiConstants";
import {NotificationManager} from "react-notifications";
import ContainerDetailSection from "../../../components/bl/ContainerDetailSection";
import ButtonLoader from "../../../util/ButtonLoader";
import ContactModal from "../../../components/contact/ContactModal";
import Common from "../../../util/common.service";
import {CONSIGNEE, NOTIFY_PARTY, SHIPPER} from "../../../constants/contactType";
import Contact from "../../../util/contact.service";
import CustomFeedback from "../../../components/formValidations/CustomFeedback";

class BLForm extends Component {

    state = {
        buttonSaveLoading: false,
        totalBooking: null,
        buttonVendorApprovalLoading: false,
        buttonApproveLoading: false,
        buttonReturnForCorrectionLoading: false,
        buttonRejectLoading: false,
        loading: true,
        modalLoading: false,
        action: true,
        booking: [],
        bl_details: [],
        countryList: [],
        stateList: [],
        cityList: [],
        booking_note: '',
        success_message: '',
        selectedHsnCodeError: '',
        bl_version: [],
        updatedFields: [],
        totalCargoWeight: null,
        totalNetWeight: null,
        // tabIndex counter, inputs are hard coded upto 8 as it contains custom order
        tabIndex: 9,
        // another tabIndex counter for container details section inputs as this section is being rendered separately
        tabIndex2: 30,
        showContainerDetails: false,
        bl_status: DRAFT,
        showCommentBox: false,
        consigneeList: [],
        notifyPartyList: [],
        selectedConsignee: null,
        consignee_address: null,
        shipper_address: null,
        notify1_address: null,
        notify2_address: null,
        consigneeError: null,
        notify1Error: null,
        notify2Error: null,
        selectedNotify1: null,
        selectedNotify2: null,
        shipperList: [],
        shipperError: null,
        selectedShipper: null,
        modalContactOpen: false,
        packageType: 'Packages',
        removedContainers: [],
        packageList: [],
        bl_comments: null,
        accordion: [false],
    };

    componentDidMount() {
        this.setBookingDetails(this.props.booking);
        this.getPackageType();
        this.getCountryDetails();
    };

    getPackageType() {
        Request.get(API_URL + 'package-type')
            .then(response => {
                this.setState({packageList: response.data.data});
            }).catch(error => error);
    };


    setBookingDetails = () => {
        let data;
        let containersArray = [];
        if (this.props.bl_id) {
            data = {
                bl_id: this.props.bl_id
            }
        } else if (this.props.containers) {
            data = {
                containers: JSON.parse("[" + this.props.containers + "]")
            }
            containersArray = JSON.parse("[" + this.props.containers + "]");
        }

        Request.post(API_URL + 'user/start-bl', data).then((response) => {

            if (response.data.status === "success") {
                let consigneeList = [], notifyPartyList = [], shipperList = [];
                for (let consignee of response.data.consignee) {
                    consigneeList.push({
                        label: consignee.company_name,
                        value: consignee.id,
                        key: consignee.id,
                        address: consignee.address,
                        email: consignee.email,
                        mobile: consignee.mobile
                    });
                }
                for (let notify1 of response.data.notify_party) {
                    notifyPartyList.push({
                        label: notify1.company_name,
                        value: notify1.id,
                        key: notify1.id,
                        address: notify1.address,
                        email: notify1.email,
                        mobile: notify1.mobile
                    });
                }
                for (let shipper of response.data.shipper) {
                    shipperList.push({
                        label: shipper.company_name,
                        value: shipper.id,
                        key: shipper.id,
                        address: shipper.address,
                        email: shipper.email,
                        mobile: shipper.mobile
                    });
                }

                let selectedConsignee = null, selectedNotify1 = null, selectedNotify2 = null, selectedShipper = null;
                if (response.data.bl_data && response.data.bl_data.consignee) {
                    selectedConsignee = {
                        label: response.data.bl_data.consignee.company_name,
                        value: response.data.bl_data.consignee.id,
                        key: response.data.bl_data.consignee.id,
                        address: response.data.bl_data.consignee.address ? response.data.bl_data.consignee.address.toUpperCase() : "",
                        email: response.data.bl_data.consignee.email,
                        mobile: response.data.bl_data.consignee.mobile
                    }
                }

                if (response.data.bl_data && response.data.bl_data.notify1) {
                    selectedNotify1 = {
                        label: response.data.bl_data.notify1.company_name,
                        value: response.data.bl_data.notify1.id,
                        key: response.data.bl_data.notify1.id,
                        address: response.data.bl_data.notify1.address.toUpperCase(),
                        email: response.data.bl_data.notify1.email,
                        mobile: response.data.bl_data.notify1.mobile
                    }
                }

                if (response.data.bl_data && response.data.bl_data.notify2) {
                    selectedNotify2 = {
                        label: response.data.bl_data.notify2.company_name,
                        value: response.data.bl_data.notify2.id,
                        key: response.data.bl_data.notify2.id,
                        address: response.data.bl_data.notify2.address.toUpperCase(),
                        email: response.data.bl_data.notify2.email,
                        mobile: response.data.bl_data.notify2.mobile
                    }
                }
                if (response.data.bl_data && response.data.bl_data.shipper) {
                    selectedShipper = {
                        label: response.data.bl_data.shipper.company_name,
                        value: response.data.bl_data.shipper.id,
                        key: response.data.bl_data.shipper.id,
                        address: response.data.bl_data.shipper.address.toUpperCase(),
                        email: response.data.bl_data.shipper.email,
                        mobile: response.data.bl_data.shipper.mobile
                    }
                } else {
                    if (response.data.data[0].booking && response.data.data[0].booking.shipper) {
                        selectedShipper = {
                            label: response.data.data[0].booking.shipper.company_name,
                            value: response.data.data[0].booking.shipper.id,
                            key: response.data.data[0].booking.shipper.id,
                            address: response.data.data[0].booking.shipper.address.toUpperCase(),
                            email: response.data.data[0].booking.shipper.email,
                            mobile: response.data.data[0].booking.shipper.mobile
                        }
                    }
                }

                /*
                * calculate Freight charges of every container and stored it against every container.
                */

                for (let booking of response.data.bookingDetails) {
                    for (let container of booking.containers) {
                        let charge = 'frt_' + container.container_size;
                        container.freight_value = container.freight_charges[charge];
                    }
                }
                /*
                * get selected container's freight charge.
                */

                for (let container of response.data.data) {
                    let charge = 'frt_' + container.container_size;
                    container.freight_value = container.freight_charges[charge];
                }

                let selectedHsnCode = [];

                if (response.data.bl_data && response.data.bl_data.hsn_code) {
                    for (let hsn_code of response.data.bl_data.hsn_code) {
                        selectedHsnCode.push({
                            label: hsn_code.code + "" + hsn_code.name,
                            value: hsn_code.id,
                            key: hsn_code.id
                        });
                    }
                }
                let bookings = [];
                if (response.data.data) {
                    for (let container of response.data.data) {
                        if (!bookings.includes(container.booking.id)) {
                            bookings.push(container.booking.id);
                        }
                    }
                }

                let bl_version = null;
                if (response.data.bl_data && response.data.bl_data.bl_version && response.data.bl_data.bl_version.length) {
                    let bl_versions = response.data.bl_data.bl_version[response.data.bl_data.bl_version.length - 1];
                    let user = localStorage.getItem('auth-user');
                    if (bl_versions) {
                        if (bl_versions.created_by !== JSON.parse(user).id) {
                            bl_version = bl_versions;
                        }
                    }
                }

                let packageType = "Packages";

                if (response.data.data && response.data.data[0].package) {
                    packageType = response.data.data[0].package.name;
                    response.data.data.map((container, key) => {
                        if (container.package.name !== packageType) {
                            packageType = "Packages";
                        } else {
                            packageType = container.package.name
                        }
                    });
                }

                let bl_comments = [];
                if (response.data.bl_data && response.data.bl_data.bl_comments) {
                    let comments = response.data.bl_data.bl_comments;
                    for (let comment in comments) {
                        let bl_comment = comments[comment];
                        bl_comments.push(
                            <tr key={comment}>
                                <td>{blStatus[bl_comment.status]}</td>
                                <td>{bl_comment.comment}</td>
                                {/*<td>{bl_comment.user.first_name + ' ' + bl_comment.user.last_name}</td>*/}
                                <td>{bl_comment.created_at}</td>
                            </tr>);
                    }
                }

                this.setState({
                    bl_comments,
                    packageType,
                    totalBooking: bookings ? bookings.length : null,
                    bl_id: this.props.bl_id ? this.props.bl_id : null,
                    selectedContainers: response.data.data,
                    bl_details: response.data.bl_data,
                    bl_version,
                    bookingDetails: response.data.bookingDetails,
                    notify2_address: response.data.bl_data ? response.data.bl_data.notify2_address : null,
                    notify1_address: response.data.bl_data ? response.data.bl_data.notify1_address : null,
                    shipper_address: response.data.bl_data ? response.data.bl_data.shipper_address : null,
                    consignee_address: response.data.bl_data ? response.data.bl_data.consignee_address : null,
                    selectedConsignee,
                    selectedNotify1,
                    selectedNotify2,
                    selectedShipper,
                    booking: response.data.data[0].booking,
                    containers: response.data.containers,
                    OriginalContainers: response.data.containers,
                    showContainerDetails: true,
                    consigneeList,
                    notifyPartyList,
                    shipperList,
                    containersArray,
                    selectedHsnCode,
                    bl_status: (response.data.bl_data && response.data.bl_data.bl_status) ? response.data.bl_data.bl_status : DRAFT,
                    loading: false,
                    showCommentBox: (response.data.bl_data && response.data.bl_data.bl_status) ? (parseInt(response.data.bl_data.bl_status) === USER_APPROVAL_REQUEST || parseInt(response.data.bl_data.bl_status) === REVISE_FIRST_PRINT) : false
                });

            } else {
                NotificationManager.warning('Warning message', response.data.message);
            }

        }).catch(error => error);
    }

    getCountryDetails = () => {
        let countryList = [], selectedCountry = null;
        Common.getAllCountry()
            .then(response => {
                let country_list = response.data.data;
                for (let country in country_list) {
                    if (country_list[country].id === 96) {
                        selectedCountry = {
                            label: country_list[country].name,
                            value: country_list[country].id,
                            key: country_list[country].id
                        }
                    }

                    countryList.push({
                        label: country_list[country].name,
                        value: country_list[country].id,
                        key: country_list[country].id
                    })
                }
                this.setState({countryList, country: selectedCountry}, this.getStateDetails)
            })
            .catch(error => error);
    };


    getStateDetails = () => {
        let stateList = [];
        Common.stateByCountry(this.state.country.value)
            .then(response => {
                let state_list = response.data.data;
                for (let state in state_list) {
                    stateList.push({
                        label: state_list[state].name,
                        value: state_list[state].id,
                        key: state_list[state].id
                    })
                }
                this.setState({stateList});
            });
    }

    handleInputChangeVersion = (event) => {
        let updatedFields = this.state.updatedFields;
        if (event.target.getAttribute('ogvalue') !== event.target.value) {
            if (!updatedFields.includes(event.target.name)) {
                updatedFields.push(event.target.name);
            }
        } else {
            let fields = [];
            updatedFields.forEach((value, i) => {
                if (value !== event.target.name) {
                    fields.push(value);
                }
            });
            updatedFields = fields;
        }
        this.setState({updatedFields: updatedFields});
    };

    toggleAccordion = (tab) => {
        const prevState = this.state.accordion;
        const state = prevState.map((x, key) => (tab === key ? !x : false));
        this.setState({
            accordion: state
        });
    };

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\s\s+/g, ' ');
        if (inputValue.length > 2) {
            Search.searchHsnCode(inputValue)
                .then((response) => {
                    let data = response.data;
                    if (data.status === 'success') {
                        let selection_list = [], hsn_list = data.data;
                        for (let hsn of hsn_list) {
                            let obj = {
                                label: hsn.code + '-' + hsn.name,
                                value: hsn.id,
                                key: hsn.id,
                                isOriginal: (hsn.id === this.state.ogHsnCodeValue)
                            };
                            selection_list.push(obj);
                        }
                        this.setState({hsnCode: selection_list});
                    }
                }).catch(err => err);
        }
        return inputValue;
    };

    handleHsnCodeChange = (hsn) => {
        let updatedFields = this.state.updatedFields;
        if (hsn && hsn.isOriginal) {
            if (updatedFields.includes('hsn_code_id')) {
                updatedFields.forEach((value, i) => {
                    if (value === 'hsn_code_id') {
                        updatedFields.splice(i, 1);
                    }
                });
            }
        } else {
            if (!updatedFields.includes('hsn_code_id')) {
                updatedFields.push('hsn_code_id');
            }
        }
        this.setState({updatedFields, selectedHsnCode: hsn, selectedHsnCodeError: null});
    };

    approveBL = () => {
        this.setState({bl_status: USER_APPROVED});
        document.getElementById("submit_button").click();
    };

    returnForCorrection = () => {
        this.setState({bl_status: RETURN_FOR_CORRECTION});
        document.getElementById("submit_button").click();
    };


    submitToVendor = () => {
        this.setState({bl_status: SEND_TO_VENDOR});
        // document.getElementById("submit_button").click();
    };

    rejectBL = () => {
        this.setState({bl_status: USER_REJECTED});
        document.getElementById("submit_button").click();
    };

    onSubmit = (event, errors, values) => {
        let selectedHsnCodeError = null, shipperError = null, consigneeError = null, notify1Error = null;
        if (this.state.selectedHsnCode == null) {
            selectedHsnCodeError = "Please select HSN Code.";
            errors.push('hsn_code_id');
        }
        if (!this.state.selectedShipper && this.state.booking.user.company.company_type === 2) {
            shipperError = "Please select Shipper."
            errors.push('shipper');
        }
        if (!this.state.selectedConsignee) {
            consigneeError = "Please select Consignee."
            errors.push('consignee');
        }
        if (!this.state.selectedNotify1) {
            notify1Error = "Please select Notify1."
            errors.push('notify1');
        }
        this.setState({selectedHsnCodeError, shipperError, consigneeError, notify1Error});

        if (errors.length !== 0) {
            var error_element = document.getElementsByName(errors[0]);
            error_element[0].parentNode.parentNode.scrollIntoView();
        } else {
            let formData = values;
            for (let field in formData) {
                if (typeof formData[field] === 'string') {
                    formData[field] = formData[field].toUpperCase();
                }
            }
            if (this.state.buttonSaveLoading === false && this.state.buttonVendorApprovalLoading === false && this.state.buttonApproveLoading === false && this.state.buttonRejectLoading === false && this.state.buttonReturnForCorrectionLoading === false) {
                let buttonSaveLoading = false,
                    buttonVendorApprovalLoading = false,
                    buttonApproveLoading = false,
                    buttonRejectLoading = false,
                    buttonReturnForCorrectionLoading = false;
                if (this.state.bl_status === DRAFT) {
                    buttonSaveLoading = true;
                } else if (this.state.bl_status === SEND_TO_VENDOR) {
                    buttonVendorApprovalLoading = true;
                } else if (this.state.bl_status === USER_APPROVED) {
                    buttonApproveLoading = true;
                } else if (this.state.bl_status === USER_REJECTED) {
                    buttonRejectLoading = true;
                } else if (this.state.bl_status === RETURN_FOR_CORRECTION) {
                    buttonReturnForCorrectionLoading = true;
                }

                let hsn_code = [];
                for (let hsn of this.state.selectedHsnCode) {
                    hsn_code.push(parseInt(hsn.value));
                }

                this.setState({
                    buttonSaveLoading,
                    buttonVendorApprovalLoading,
                    buttonApproveLoading,
                    buttonRejectLoading,
                    buttonReturnForCorrectionLoading
                });

                let data = {
                    ...values,
                    pre_carriage: (values.pre_carriage) ? values.pre_carriage : null,
                    updated_fields: this.state.updatedFields,
                    shipper_id: this.state.selectedShipper ? this.state.selectedShipper.value : "",
                    consignee_id: this.state.selectedConsignee ? this.state.selectedConsignee.value : "",
                    notify1_id: this.state.selectedNotify1 ? this.state.selectedNotify1.value : "",
                    notify2_id: this.state.selectedNotify2 ? this.state.selectedNotify2.value : "",
                    containers: this.state.containers,
                    pol_code: this.state.booking.pol.port_code,
                    pod_id: this.state.booking.pod.id,
                    pol_id: this.state.booking.pol.id,
                    user_id: this.state.booking.user_id,
                    shipline_id: this.state.booking.shipline_id,
                    pol_agent_id: this.state.booking.pol_agent_id,
                    pol_agent_company_id: this.state.booking.pol_agent_company_id,
                    pod_code: this.state.booking.pod.port_code,
                    removedContainers: this.state.removedContainers,
                    booking_id: this.state.booking.id,
                    bl_id: this.props.bl_id,
                    hsn_code_id: hsn_code,
                    total_packages: this.state.TotalNoPackages,
                    gross_weight: this.state.totalCargoWeight,
                    net_weight: this.state.totalNetWeight,
                    bl_status: this.state.bl_status
                };
                this.props.onSubmit(data);
            }
        }
    };

    setParentState = (name, value) => {
        this.setState({[name]: value});
    }

    toggleAddContactModal = (type, contact, address) => {
        this.setState({
            modalContactOpen: !this.state.modalContactOpen,
            selectedContactType: type,
            contact: contact,
            address: address
        });
    }

    handleChange = (name, list, error, selected, address) => {
        let errorName = null;
        this.setState({[name]: selected, [error]: errorName, [address]: null});
    };

    addAddress = (data) => {
        this.setState({modalLoading: true});
        Contact.add(data)
            .then(response => {
                if (response.data.status === "success") {

                    let contact = {
                        label: response.data.data[0].company_name.toUpperCase(),
                        value: response.data.data[0].id,
                        key: response.data.data[0].id,
                        address: response.data.data[0].address.toUpperCase(),
                        mobile: response.data.data[0].mobile,
                        email: response.data.data[0].email
                    }
                    let contactList = [];
                    for (let contacts of response.data.data) {
                        contactList.push({
                            label: contacts.company_name,
                            value: contacts.id,
                            key: contacts.id,
                            address: contacts.address,
                            email: contacts.email,
                            mobile: contacts.mobile
                        });
                    }
                    this.setState({[this.state.contact]: contact});
                    if (this.state.contactType === CONSIGNEE) {
                        this.setState({consigneeList: contactList});
                    } else if (this.state.contactType === NOTIFY_PARTY) {
                        this.setState({notifyPartyList: contactList});
                    } else if (this.state.contactType === SHIPPER) {
                        this.setState({shipperList: contactList})
                    }
                    this.setState({
                        modalContactOpen: !this.state.modalContactOpen,
                        modalLoading: false,
                        [this.state.address]: null
                    });
                }
            })
            .catch(error => error);
    };

    render() {

        let {tabIndex} = this.state;
        return (
            <div>
                {this.state.loading ?
                    <div>Loading</div> :

                    <AvForm onSubmit={this.onSubmit}>
                        <div className="row">
                            <div className="col-md-12">

                            </div>
                        </div>
                        <div className="container-fluid itembg">
                            {/*<p className="bl-notice">*/}
                            {/*    *Textboxes highlighted in Purple colour indicates changes done by the vendor.*/}
                            {/*</p>*/}
                            <div className="div-static-data-full new-blfrom">

                                <div className="row">
                                    <div className="col-lg-3 col-sm-3">
                                        <div className="static-data-input">
                                            <label>Port of Loading</label>
                                            <p>{this.state.booking.pol.port_name}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <div className="static-data-input">
                                            <label>Port of Discharge</label>
                                            <p>{this.state.booking.pod.port_name}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <div className="static-data-input">
                                            <label>Vessel/Voyage</label>
                                            <p>{this.state.booking.vessel} / {this.state.booking.voyage}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <div className="static-data-input">
                                            <label>Commodity</label>
                                            <p>{this.state.booking.commodity.title}</p>
                                        </div>
                                    </div>
                                    {this.state.bl_details.bl_number ?
                                        <div className="col-lg-3 col-sm-3">
                                            <div className="static-data-input">
                                                <label>BL Number</label>
                                                <p>{this.state.bl_details.bl_number}</p>
                                            </div>
                                        </div> : null
                                    }
                                </div>
                                {/*<div className="row">*/}
                                {/*<div className="col-lg-12 col-sm-12">*/}
                                {/*<div className="note-text-added">*/}
                                {/*    <p>To order BS/L or MTD cannot be surrendered at origin.</p>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                <div className="row modal-data-input">

                                    {
                                        this.state.booking && this.state.booking.user && this.state.booking.user.company.company_type === 2 ?
                                            <div className="col-lg-3 col-sm-3">
                                                <label>Shipper <span className="required">*</span> <i
                                                    title="Add shipper" className="fa fa-plus-circle"
                                                    onClick={() => this.toggleAddContactModal(SHIPPER, 'selectedShipper', 'shipper_address')}/></label>
                                                <Select
                                                    className="home-select"
                                                    name="shipper"
                                                    className={(this.state.bl_version != null && this.state.bl_version.shipper_id !== this.state.bl_details.shipper_id) ? "changed-input" : ""}
                                                    value={this.state.selectedShipper}
                                                    ogvalue={this.state.selectedShipper}
                                                    placeholder="Select shipper"
                                                    noOptionsMessage={() => "Start typing shipper"}
                                                    onChange={(selected) => this.handleChange('selectedShipper', 'shipperList', 'shipperError', selected, 'shipper_address')}
                                                    options={this.state.shipperList}
                                                />
                                                <CustomFeedback>{this.state.shipperError}</CustomFeedback>
                                            </div> :
                                            <div className="col-lg-3 col-sm-3">
                                                <AvGroup className="mb-0">
                                                    <label>Shipper</label>
                                                    <AvField name="shipper_name" id="shipper_name" type="textarea"
                                                             value={this.state.booking.user ? this.state.booking.user.company.name : ""}
                                                             readOnly/>
                                                </AvGroup>
                                            </div>
                                    }

                                    <div className="col-lg-3 col-sm-3">
                                        <label>Consignee <span className="required">*</span> <i title="Add Consignee"
                                                                                                className="fa fa-plus-circle"
                                                                                                onClick={() => this.toggleAddContactModal(CONSIGNEE, 'selectedConsignee', 'consignee_address')}/></label>


                                        <Select
                                            className="home-select"
                                            name="consignee"
                                            className={(this.state.bl_version != null && this.state.bl_version.consignee_id !== this.state.bl_details.consignee_id) ? "changed-input" : ""}
                                            value={this.state.selectedConsignee}
                                            ogvalue={this.state.selectedConsignee}
                                            placeholder="Select consignee"
                                            noOptionsMessage={() => "Start typing Exporter"}
                                            onChange={(selected) => this.handleChange('selectedConsignee', 'consigneeList', 'consigneeError', selected, 'consignee_address')}
                                            options={this.state.consigneeList}
                                        />
                                        <p className="consignee-name-line"><i className="fa fa-exclamation-circle"/>To
                                            Order BS/L Or MTD Cannot Be Surrendered At Origin.</p>
                                        <CustomFeedback>{this.state.consigneeError}</CustomFeedback>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <label>Notify1 <span className="required">*</span> <i title="Add Notify1"
                                                                                              className="fa fa-plus-circle"
                                                                                              onClick={() => this.toggleAddContactModal(NOTIFY_PARTY, 'selectedNotify1', 'notify1_address')}/></label>
                                        <Select
                                            className="home-select"
                                            name="notify1"
                                            value={this.state.selectedNotify1}
                                            ogvalue={this.state.selectedNotify1}
                                            className={(this.state.bl_version != null && this.state.bl_version.notify1_id !== this.state.bl_details.notify1_id) ? "changed-input" : ""}
                                            placeholder="Select notify1"
                                            noOptionsMessage={() => "Start typing Exporter"}
                                            onChange={(selected) => this.handleChange('selectedNotify1', 'notifyPartyList', 'notify1Error', selected, 'notify1_address')}
                                            options={this.state.notifyPartyList}
                                        />
                                        <CustomFeedback>{this.state.notify1Error}</CustomFeedback>
                                    </div>

                                    <div className="col-lg-3 col-sm-3">
                                        <label>Notify2 <i title="Add Notify2" className="fa fa-plus-circle"
                                                          onClick={() => this.toggleAddContactModal(NOTIFY_PARTY, 'selectedNotify2', 'notify2_address')}/></label>
                                        <Select
                                            className="home-select"
                                            name="notify2"
                                            className={(this.state.bl_version != null && this.state.bl_version.notify2_id !== this.state.bl_details.notify2_id) ? "changed-input" : ""}
                                            value={this.state.selectedNotify2}
                                            ogvalue={this.state.selectedNotify2}
                                            placeholder="Select notify2"
                                            noOptionsMessage={() => "Start typing Notify2"}
                                            onChange={(selected) => this.handleChange('selectedNotify2', 'notifyPartyList', 'notify2Error', selected, 'notify2_address')}
                                            options={this.state.notifyPartyList}
                                        />
                                    </div>

                                    <div className="col-lg-3 col-sm-3">
                                        {this.state.booking && this.state.booking.user && this.state.booking.user.company.company_type === 2 ?
                                            <AvGroup className="error-t-negative">
                                                <label>Shipper Address <span className="required">*</span></label>
                                                <AvField name="shipper_address" id="shipper_address" type="textarea"
                                                         className={(this.state.bl_version != null && this.state.bl_version.shipper_address !== this.state.bl_details.shipper_address) ? "changed-input" : ""}
                                                         onChange={this.handleInputChangeVersion}
                                                         value={(this.state.shipper_address ? this.state.shipper_address : (this.state.selectedShipper ? this.state.selectedShipper.address : "") + (this.state.selectedShipper && this.state.selectedShipper.mobile ? ', ' + this.state.selectedShipper.mobile : "") + (this.state.selectedShipper && this.state.selectedShipper.email ? ', ' + this.state.selectedShipper.email : ""))}
                                                         ogvalue={(this.state.shipper_address ? this.state.shipper_address : (this.state.selectedShipper ? this.state.selectedShipper.address : "") + (this.state.selectedShipper && this.state.selectedShipper.mobile ? ', ' + this.state.selectedShipper.mobile : "") + (this.state.selectedShipper && this.state.selectedShipper.email ? ', ' + this.state.selectedShipper.email : ""))}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Shipper Address'
                                                             }
                                                         }}/>

                                            </AvGroup> :

                                            <AvGroup className="error-t-negative">
                                                <label>Shipper Address</label>
                                                <AvField name="shipper_address" id="shipper_address" type="textarea"
                                                         className={(this.state.bl_version != null && this.state.bl_version.shipper_address !== this.state.bl_details.shipper_address) ? "changed-input" : ""}
                                                         value={this.state.booking.user ? this.state.booking.user.company.address : ""}
                                                         readOnly/>
                                            </AvGroup>
                                        }
                                    </div>


                                    <div className="col-lg-3 col-sm-3">
                                        <AvGroup className="error-t-negative">
                                            <label>Consignee Address <span className="required">*</span></label>
                                            <AvField name="consignee_address" id="consignee_address" type="textarea"
                                                     className={(this.state.bl_version != null && this.state.bl_version.consignee_address !== this.state.bl_details.consignee_address) ? "changed-input" : ""}
                                                     onChange={this.handleInputChangeVersion}
                                                     value={(this.state.consignee_address ? this.state.consignee_address : (this.state.selectedConsignee && this.state.selectedConsignee.address ? this.state.selectedConsignee.address : "") + (this.state.selectedConsignee && this.state.selectedConsignee.mobile ? ', ' + this.state.selectedConsignee.mobile : "") + (this.state.selectedConsignee && this.state.selectedConsignee.email ? ', ' + this.state.selectedConsignee.email : ""))}
                                                     ogvalue={(this.state.consignee_address ? this.state.consignee_address : (this.state.selectedConsignee && this.state.selectedConsignee.address ? this.state.selectedConsignee.address : "") + (this.state.selectedConsignee && this.state.selectedConsignee.mobile ? ', ' + this.state.selectedConsignee.mobile : "") + (this.state.selectedConsignee && this.state.selectedConsignee.email ? ', ' + this.state.selectedConsignee.email : ""))}
                                                     validate={{
                                                         required: {
                                                             value: (!(this.state.selectedConsignee && this.state.selectedConsignee.label === "To Order")),
                                                             errorMessage: 'Please enter Consignee Address'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <AvGroup className="error-t-negative">
                                            <label>Notify 1 Address <span className="required">*</span></label>
                                            <AvField name="notify1_address" id="notify1_address" type="textarea"
                                                     className={(this.state.bl_version != null && this.state.bl_version.notify1_address !== this.state.bl_details.notify1_address) ? "changed-input" : ""}
                                                     onChange={this.handleInputChangeVersion}
                                                     value={(this.state.notify1_address ? this.state.notify1_address : (this.state.selectedNotify1 ? this.state.selectedNotify1.address : "") + (this.state.selectedNotify1 && this.state.selectedNotify1.mobile ? ', ' + this.state.selectedNotify1.mobile : "") + (this.state.selectedNotify1 && this.state.selectedNotify1.email ? ', ' + this.state.selectedNotify1.email : ""))}
                                                     ogvalue={(this.state.notify1_address ? this.state.notify1_address : (this.state.selectedNotify1 ? this.state.selectedNotify1.address : "") + (this.state.selectedNotify1 && this.state.selectedNotify1.mobile ? ', ' + this.state.selectedNotify1.mobile : "") + (this.state.selectedNotify1 && this.state.selectedNotify1.email ? ', ' + this.state.selectedNotify1.email : ""))}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Notify 1 Address'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <AvGroup className="error-t-negative">
                                            <label>Notify 2 Address</label>
                                            <AvField name="notify2_address" id="notify2_address" type="textarea"
                                                     className={(this.state.bl_version != null && this.state.bl_version.notify2_address !== this.state.bl_details.notify2_address) ? "changed-input" : ""}
                                                     onChange={this.handleInputChangeVersion}
                                                     value={(this.state.notify2_address ? this.state.notify2_address : (this.state.selectedNotify2 ? this.state.selectedNotify2.address : "") + (this.state.selectedNotify2 && this.state.selectedNotify2.mobile ? ', ' + this.state.selectedNotify2.mobile : "") + (this.state.selectedNotify2 && this.state.selectedNotify2.email ? ', ' + this.state.selectedNotify2.email : ""))}
                                                     ogvalue={(this.state.notify2_address ? this.state.notify2_address : (this.state.selectedNotify2 ? this.state.selectedNotify2.address : "") + (this.state.selectedNotify2 && this.state.selectedNotify2.mobile ? ', ' + this.state.selectedNotify2.mobile : "") + (this.state.selectedNotify2 && this.state.selectedNotify2.email ? ', ' + this.state.selectedNotify2.email : ""))}
                                            />
                                        </AvGroup>
                                    </div>

                                    <div className="col-lg-3 col-sm-3">
                                        <AvGroup className="error-t-negative">
                                            <label>Place of Receipt <span className="required">*</span></label>
                                            <AvField name="place_of_receipt" id="place_of_receipt" type="text"
                                                     className={(this.state.bl_version != null && this.state.bl_version.place_of_receipt !== this.state.bl_details.place_of_receipt) ? "changed-input" : ""}
                                                     onChange={this.handleInputChangeVersion}
                                                     value={(this.state.bl_details.place_of_receipt) ? this.state.bl_details.place_of_receipt : this.state.booking.pol.port_name}
                                                     ogvalue={(this.state.bl_details.place_of_receipt) ? this.state.bl_details.place_of_receipt : this.state.booking.pol.port_name}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Place of Receipt'
                                                         }

                                                     }} tabIndex={tabIndex++}/>
                                        </AvGroup>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <AvGroup className="error-t-negative">
                                            <label>Place of Delivery <span className="required">*</span></label>
                                            <AvField name="place_of_delivery" id="place_of_delivery" type="text"
                                                     className={(this.state.bl_version != null && this.state.bl_version.place_of_delivery !== this.state.bl_details.place_of_delivery) ? "changed-input" : ""}
                                                     onChange={this.handleInputChangeVersion}
                                                     value={(this.state.bl_details.place_of_delivery) ? this.state.bl_details.place_of_delivery : this.state.booking.pod.port_name}
                                                     ogvalue={(this.state.bl_details.place_of_delivery) ? this.state.bl_details.place_of_delivery : this.state.booking.pod.port_name}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Place of Delivery'
                                                         }
                                                     }} tabIndex={tabIndex++}/>
                                        </AvGroup>
                                    </div>
                                    <div className="col-lg-3 col-sm-2">
                                        <AvGroup className="error-t-negative">
                                            <label>Pre Carriage By <span className="required">*</span></label>
                                            <AvField name="pre_carriage" id="pre_carriage" type="text"
                                                     className={(this.state.bl_version != null && this.state.bl_version.pre_carriage !== this.state.bl_details.pre_carriage) ? "changed-input" : ""}
                                                     onChange={this.handleInputChangeVersion}
                                                     value={this.state.bl_details.pre_carriage}
                                                     ogvalue={this.state.bl_details.pre_carriage}
                                                     validate={{
                                                         pattern: {
                                                             value: '/^[A-Za-z\\d\\s]+$/',
                                                             errorMessage: 'Your name must be composed only with letter and numbers'
                                                         }
                                                     }} tabIndex={tabIndex++}/>
                                        </AvGroup>
                                    </div>

                                    <div className="col-lg-3 col-sm-2">
                                        <AvGroup className="error-t-negative">
                                            <label>Type of Service <span className="required">*</span></label>
                                            <AvField type="select" name="type_of_service"
                                                     className={(this.state.bl_version != null && this.state.bl_version.type_of_service !== this.state.bl_details.type_of_service) ? "changed-input" : ""}
                                                     id="type_of_service"
                                                     onChange={this.handleInputChangeVersion}
                                                     value={this.state.bl_details && this.state.bl_details.type_of_service ? this.state.bl_details.type_of_service : (this.state.booking ? this.state.booking.type_of_service : "")}
                                                     ogvalue={this.state.bl_details && this.state.bl_details.type_of_service ? this.state.bl_details.type_of_service : (this.state.booking ? this.state.booking.type_of_service : "")}>
                                                <option value="">Select Type of Service</option>
                                                <option value="CY/CY">CY/CY</option>
                                                <option value="CY/CFS">CY/CFS</option>
                                                <option value="CY/SD">CY/SD</option>
                                                <option value="SD/CY">SD/CY</option>
                                                <option value="CFS/CY">CFS/CY</option>
                                                <option value="CFS/CFS">CFS/CFS</option>
                                                <option value="SD/SD">SD/SD</option>
                                            </AvField>
                                        </AvGroup>
                                    </div>

                                    <div className="col-lg-3 col-sm-2">
                                        <AvGroup className="error-t-negative">
                                            <label>CRO No</label>
                                            <AvField id="cro_no" name="cro_no" type="text"
                                                     className={(this.state.bl_version != null && this.state.bl_version.cro_no !== this.state.bl_details.cro_no) ? "changed-input" : ""}
                                                     onChange={this.handleInputChangeVersion}
                                                     value={this.state.bl_details && this.state.bl_details.cro_no ? this.state.bl_details.cro_no : (this.state.totalBooking && this.state.totalBooking === 1 ? this.state.booking.cro_no : this.state.totalBooking + ' (Merged)')}
                                                     ogvalue={this.state.bl_details && this.state.bl_details.cro_no ? this.state.bl_details.cro_no : (this.state.totalBooking && this.state.totalBooking === 1 ? this.state.booking.cro_no : this.state.totalBooking + ' (Merged)')}

                                            />
                                        </AvGroup>
                                    </div>

                                    {this.state.bl_details.shipping_line_no ?
                                        <div className="col-lg-3 col-sm-2">
                                            <AvGroup className="mb-0">
                                                <label> {this.state.bl_details.shipping_line_no && this.state.bl_details.bl_type === 1 ? "Shipping Line B/l No" : "Our BL No"}</label>
                                                <AvField name="shipping_line_no" id="shipping_line_no"
                                                         className={(this.state.bl_version != null && this.state.bl_version.shipping_line_no !== this.state.bl_details.shipping_line_no) ? "changed-input" : ""}
                                                         type="text"
                                                         onChange={this.handleInputChangeVersion}
                                                         ogvalue={this.state.bl_details.shipping_line_no}
                                                         value={this.state.bl_details.shipping_line_no}
                                                />
                                            </AvGroup>
                                        </div> : null
                                    }

                                    <div className="col-lg-3 col-sm-2">
                                        <AvGroup className="mb-0">
                                            <label>CHA Job No</label>
                                            <AvField name="cha_job_no" id="cha_job_no"
                                                     className={(this.state.bl_version != null && this.state.bl_version.cha_job_no !== this.state.bl_details.cha_job_no) ? "changed-input" : ""}
                                                     type="text"
                                                     onChange={this.handleInputChangeVersion}
                                                     value={this.state.bl_details.cha_job_no}
                                                     ogvalue={this.state.bl_details.cha_job_no}
                                            />
                                        </AvGroup>
                                    </div>

                                    <div className="col-lg-3 col-sm-2">
                                        <AvGroup className="mb-0">
                                            <label>Exporters Invoice No</label>
                                            <AvField name="exporter_invoice_no" id="exporter_invoice_no"
                                                     className={(this.state.bl_version != null && this.state.bl_version.exporter_invoice_no !== this.state.bl_details.exporter_invoice_no) ? "changed-input" : ""}
                                                     type="text"
                                                     onChange={this.handleInputChangeVersion}
                                                     value={this.state.bl_details.exporter_invoice_no}
                                                     ogvalue={this.state.bl_details.exporter_invoice_no}
                                            />
                                        </AvGroup>
                                    </div>

                                    {/*<div className="col-lg-2 col-sm-2">
                                        <AvGroup className="error-t-negative">
                                            <label>Required BL Type</label>
                                            <AvField id="bl_type" name="bl_type" type="select"
                                                     ogvalue={this.state.bl_details.bl_type}
                                                     onChange={this.handleInputChangeVersion}
                                                     className={(this.state.bl_version != null && this.state.bl_version.bl_type !== this.state.booking.bl.bl_type) ? "changed-input" : ""}
                                                     value={this.state.bl_details.bl_type}
                                                     tabIndex={tabIndex++}>
                                                <option value="1">Master BL</option>
                                                <option value="2">House BL</option>
                                            </AvField>
                                        </AvGroup>
                                    </div>*/}

                                    <div className="col-lg-9 col-sm-9">
                                        <AvGroup className="error-t-negative">
                                            <label>HS Code</label>
                                            <Select
                                                // components={{Input: CustomSelectInput}}
                                                classNamePrefix="react-select"
                                                name="hsn_code_id"
                                                value={(this.state.selectedHsnCode) ? this.state.selectedHsnCode : null}
                                                ogvalue={(this.state.selectedHsnCode) ? this.state.selectedHsnCode : null}
                                                id="hsn_code_id"
                                                onChange={(hsn) => {
                                                    this.handleHsnCodeChange(hsn)
                                                }}
                                                isMulti={true}
                                                options={this.state.hsnCode}
                                                placeholder="HS Code"
                                                onInputChange={(inputValue) => this.handleInputChange(inputValue)}
                                                noOptionsMessage={() => "Start typing HS Code"}
                                                tabIndex={tabIndex++}
                                            />
                                            <label
                                                style={{color: "red"}}>{this.state.selectedHsnCodeError}</label>

                                        </AvGroup>
                                    </div>

                                    <div className="col-lg-2 col-sm-2">
                                        <AvGroup className="error-t-negative">
                                            <label>BL Type <span className="required">*</span></label>
                                            <AvField
                                                type="select"
                                                name="bl_issued_type"
                                                className={(this.state.bl_version != null && this.state.bl_version.bl_issued_type !== this.state.bl_details.bl_issued_type) ? "changed-input" : ""}
                                                onChange={this.handleInputChangeVersion}
                                                value={this.state.bl_details.bl_issued_type}
                                                ogvalue={this.state.bl_details.bl_issued_type}
                                                errorMessage="Please select an option!"
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: 'Please select BL type'
                                                    }
                                                }}>
                                                <option value="">Select</option>
                                                <option value="1">Seaway</option>
                                                <option value="2">Original</option>
                                            </AvField>
                                        </AvGroup>
                                    </div>
                                </div>
                                <h4 className="card-header">ShipmentDetails</h4>
                                <div className="modal-data-input">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th width="15%">Marks (Max 500 characters allowed)</th>
                                            <th width="15%">Packages</th>
                                            <th width="40%">Description of Packages & Goods (Max 2000 characters
                                                allowed) <span className="required">*</span>
                                            </th>
                                            <th width="15%">Gross.Wt</th>
                                            <th width="15%">Measurement</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <AvGroup className="error-t-negative">
                                                    <AvField name="marks_seal" id="marks_seal" type="textarea"
                                                             className={(this.state.bl_version != null && this.state.bl_version.marks_seal !== this.state.bl_details.marks_seal) ? "changed-input" : ""}
                                                             rows="5"
                                                             ogvalue={this.state.bl_details.marks_seal}
                                                             onChange={this.handleInputChangeVersion}
                                                             value={this.state.bl_details.marks_seal}
                                                             validate={{
                                                                 maxLength: {
                                                                     value: 500,
                                                                     errorMessage: 'You have reached your maximum limit of characters allowed'
                                                                 }
                                                             }} tabIndex={tabIndex++}
                                                    />
                                                </AvGroup>
                                            </td>
                                            <td>
                                                <AvGroup className="error-t-negative">
                                                    <AvField name="packages" id="packages" type="textarea" rows="5"
                                                             value={this.state.TotalNoPackages + " " + this.state.packageType}
                                                             readOnly/>
                                                </AvGroup>
                                            </td>
                                            <td>
                                                <AvGroup className="error-t-negative">
                                                    <AvField name="goods_description" id="goods_description"
                                                             className={(this.state.bl_version != null && this.state.bl_version.goods_description !== this.state.bl_details.goods_description) ? "changed-input" : ""}
                                                             type="textarea" rows="5"
                                                             ogvalue={this.state.bl_details.goods_description}
                                                             onChange={this.handleInputChangeVersion}
                                                             value={this.state.bl_details.goods_description}
                                                             validate={{
                                                                 required: {
                                                                     value: true,
                                                                     errorMessage: 'Please enter Description of Packages & Goods'
                                                                 },
                                                                 maxLength: {
                                                                     value: 2000,
                                                                     errorMessage: 'You have reached your maximum limit of characters allowed'
                                                                 }
                                                             }} tabIndex={tabIndex++}/>
                                                </AvGroup>
                                            </td>
                                            <td>
                                                <AvGroup className="error-t-negative">
                                                    <AvField name="gross_wt" id="gross_wt" type="textarea" rows="5"
                                                             value={this.state.totalCargoWeight + ' KG'} readOnly/>
                                                </AvGroup>
                                            </td>
                                            <td>
                                                <AvGroup className="error-t-negative">
                                                    <AvField name="measurement" id="measurement"
                                                             className={(this.state.bl_version != null && this.state.bl_version.measurement !== this.state.bl_details.measurement) ? "changed-input" : ""}
                                                             ogvalue={this.state.bl_details.measurement}
                                                             onChange={this.handleInputChangeVersion}
                                                             type="textarea" rows="5"
                                                             value={this.state.bl_details.measurement}
                                                             validate={{
                                                                 pattern: {
                                                                     value: '^[0-9a-zA-Z]*$',
                                                                     errorMessage: 'Please enter valid Measurement'
                                                                 }
                                                             }} tabIndex={tabIndex++}/>
                                                </AvGroup>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                {this.state.showContainerDetails ?
                                    <ContainerDetailSection selectedContainers={this.state.selectedContainers}
                                                            bookingDetails={this.state.bookingDetails}
                                                            bl_id={this.state.bl_id}
                                                            packageList={this.state.packageList}
                                                            OriginalContainers={this.state.OriginalContainers}
                                                            handleInputChangeVersion={this.handleInputChangeVersion}
                                                            containersArray={this.state.containersArray}
                                                            containers={this.state.containers}
                                                            booking_details={this.state.booking}
                                                            setParentState={this.setParentState}
                                                            packageType={this.state.packageType}
                                                            bl_version={this.state.bl_version}/> : null}


                                <h4 className="card-header">References</h4>
                                <div className="row modal-data-input">
                                    <div className="col-lg-2 col-sm-2">
                                        <AvGroup className="error-t-negative">
                                            <label>Shipper Ref</label>
                                            <AvField name="shipper_ref" id="shipper_ref" type="text"
                                                     className={(this.state.bl_version != null && this.state.bl_version.shipper_ref !== this.state.bl_details.shipper_ref) ? "changed-input" : ""}
                                                     onChange={this.handleInputChangeVersion}
                                                     ogvalue={this.state.bl_details.shipper_ref}
                                                     value={this.state.bl_details.shipper_ref}/>
                                        </AvGroup>
                                    </div>

                                    <div className="col-lg-2 col-sm-2">
                                        <AvGroup className="error-t-negative">
                                            <label>Export Ref</label>
                                            <AvField name="export_ref" id="export_ref" type="text"
                                                     className={(this.state.bl_version != null && this.state.bl_version.export_ref !== this.state.bl_details.export_ref) ? "changed-input" : ""}
                                                     ogvalue={this.state.bl_details.export_ref}
                                                     onChange={this.handleInputChangeVersion}
                                                     value={this.state.bl_details.export_ref}/>
                                        </AvGroup>
                                    </div>
                                </div>

                                {this.state.showCommentBox ?
                                    <div className="row modal-data-input">
                                        <div className="col-md-12">
                                            <div className="itembg h-vgm">
                                                <AvGroup className="error-t-negative">
                                                    <label>Remarks (Max 1500 characters
                                                        allowed) {parseInt(this.state.bl_status) === USER_REJECTED ?
                                                            <span className="required">*</span> : ""}</label>
                                                    <AvField name="bl_comment" id="bl_comment" type="textarea"
                                                             maxLength="1500"
                                                             validate={{
                                                                 required: {
                                                                     value: parseInt(this.state.bl_status) === USER_REJECTED,
                                                                     errorMessage: 'Remarks are mandatory for rejection'
                                                                 },
                                                                 maxLength: {
                                                                     value: 1500,
                                                                     errorMessage: 'You have reached your maximum limit of characters allowed'
                                                                 }
                                                             }}/>
                                                </AvGroup>
                                            </div>
                                        </div>
                                    </div> : ''}
                                <button style={{display: 'none'}} id="submit_button">Submit</button>
                            </div>
                            {((this.state.bl_details.bl_status === USER_REJECTED) ||
                                (this.state.bl_details.bl_status === DRAFT) || this.state.bl_status === DRAFT || Object.entries(this.state.bl_details).length === 0) ?
                                <div className="row">
                                    <div className="bl_sub">
                                        <button className="btn primary-btn" id="submit_vendor"
                                                onClick={this.submitToVendor}>
                                            <ButtonLoader color="primary"
                                                          isLoading={this.state.buttonVendorApprovalLoading}
                                                          label="Submit to Vendor"/>
                                        </button>
                                        &nbsp;&nbsp;
                                        <button className="btn primary-btn" id="submit_button">
                                            <ButtonLoader color="primary"
                                                          isLoading={this.state.buttonSaveLoading}
                                                          label="Save"/>
                                        </button>
                                    </div>
                                </div> :
                                (this.state.bl_details.bl_status === USER_APPROVAL_REQUEST) ?
                                    <div className="row">
                                        <div className="bl_sub">
                                            <button className="btn primary-btn" id="return_for_correction"
                                                    onClick={this.returnForCorrection}>
                                                <ButtonLoader color="primary"
                                                              isLoading={this.state.buttonReturnForCorrectionLoading}
                                                              label="Return For Correction"/>
                                            </button>
                                            &nbsp;&nbsp;
                                            <button className="btn primary-btn" id="approve_bl"
                                                    onClick={this.approveBL}>
                                                <ButtonLoader color="primary"
                                                              isLoading={this.state.buttonApproveLoading}
                                                              label="Approve"/>
                                            </button>
                                        </div>
                                    </div> : ''
                            }

                            {this.state.bl_details.bl_status === REVISE_FIRST_PRINT ?
                                <div className="row">
                                    <div className="bl_sub">
                                        <button className="btn primary-btn" id="approve_bl"
                                                onClick={this.approveBL}>
                                            <ButtonLoader color="primary"
                                                          isLoading={this.state.buttonApproveLoading}
                                                          label="Approve"/>
                                        </button>
                                        &nbsp;&nbsp;
                                        <button className="btn primary-btn" id="reject_bl" onClick={this.rejectBL}>
                                            <ButtonLoader color="primary"
                                                          isLoading={this.state.buttonRejectLoading}
                                                          label="Reject"/>
                                        </button>
                                    </div>
                                </div> : null
                            }

                        </div>
                    </AvForm>}
                {this.state.bl_comments ?
                    <div>
                        <Card className="mb-5">
                            <div className="border">
                                <Button
                                    block
                                    color="link"
                                    className="text-left accordion-header"
                                    onClick={() => this.toggleAccordion(0)}
                                    aria-expanded={this.state.accordion[0]}>
                                    Comments &nbsp;
                                    {this.state.accordion[0] ?
                                        <i className="fa fa-minus accordion-icon"/> :
                                        <i className="fa fa-plus accordion-icon"/>}
                                </Button>
                                <Collapse isOpen={this.state.accordion[0]}>

                                    <CardBody>
                                        <table className="details_table table table-sm">
                                            <thead>
                                            <tr>
                                                <th>Bl Status</th>
                                                <th>Comment</th>
                                                {/*<th>Comment By</th>*/}
                                                <th>Commented At</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {this.state.bl_comments}
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Collapse>
                            </div>
                        </Card>
                    </div> : null
                }

                {this.state.modalContactOpen ?
                    <ContactModal addAddress={this.addAddress}
                                  countryList={this.state.countryList}
                                  stateList={this.state.stateList}
                                  cityList={this.state.cityList}
                                  country={this.state.country}
                                  modalOpen={this.state.modalContactOpen}
                                  action={this.state.action}
                                  toggleAddModal={this.toggleAddContactModal}
                                  modalLoading={this.state.modalLoading}
                                  selectedContactType={this.state.selectedContactType}/>
                    : null}
            </div>
        );
    }

}

export default BLForm;