import React, {Component, Fragment} from "react";
import Enquiry from "../../../util/enquiry.service";
import {Col, Container, Row, Table} from "reactstrap";
import TopMenu from "../TopMenu";
import ProductRow from "../../../components/checkList/ProductRow";
import {AvForm} from "availity-reactstrap-validation";
import SBTable from "../../../components/checkList/SBTable";

class CheckListDetails extends Component {

    state = {
        enquiryDetails: [],
        products: [],
        productsCount: []
    };

    componentDidMount() {
        this.getEnquiryDetails();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.getEnquiryDetails();
        }
    };

    getEnquiryDetails = () => {
        this.setState({loading: true});
        Enquiry.details(this.props.match.params.id)
            .then(response => {
                let enquiryDetails = response.data.data;
                this.setState({
                    enquiryDetails
                });
            }).catch(error => error);
    };

    toggleAddProductModal = () => {
        let productsCount = this.state.productsCount;
        if (productsCount.length) {
            let last = productsCount[productsCount.length - 1];
            if (last < 50) {
                productsCount.push(parseInt(last) + parseInt(1));
            } else {
                alert(`Only upto 50 additional emails allowed.`);
            }
        } else {
            productsCount.push(1);
        }
        this.setState({productsCount});
    };


    render() {

        let products = [];
        if (this.state.productsCount) {
            for (let count of this.state.productsCount) {
                products.push(<ProductRow/>);
            }
        }

        return (
            <Fragment>
                <TopMenu/>
                <Container>
                    <Row>
                        <Col lg="8" sm="8">
                            {/*  <Switch>
                                <Redirect to={`${match.url}/history`}/>
                            </Switch>*/}
                        </Col>
                        <AvForm onSubmit={this.onSubmit}>
                        <Col lg="12" sm="12">
                            <SBTable/>
                        </Col>
                        </AvForm>
                        <Col lg="12" sm="12">
                            <div className="">
                                <Table bordered>
                                    <thead>
                                    <tr>
                                        <th>Sr.No.</th>
                                        <th>Hs Code. <span className="required">*</span></th>
                                        <th>Product <span className="required">*</span></th>
                                        <th>Package Type</th>
                                        <th>Package No <span className="required">*</span></th>
                                        <th>Gross Weight <span className="required">*</span></th>
                                        <th>Net Weight <span className="required">*</span></th>
                                        <th>Rate <span className="required">*</span></th>
                                        <th>Gross Wt(Total) <span className="required">*</span></th>
                                        <th>Net Weight(Total) <span className="required">*</span></th>
                                        <th>
                                            <i title="Add Product" className="fa fa-plus-circle"
                                               onClick={this.toggleAddProductModal}/>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }


}

export default CheckListDetails;