import React, {Component, Fragment} from "react";
import {Row} from "reactstrap";
import BLForm from "./BLForm";
import {NotificationContainer} from "react-notifications";
import axios from "axios";
import {API_URL} from "../../../constants/apiConstants";
import Storage from "../../../util/storage.service";

class draftBLPage extends Component {

    state = {
        isLoading: true,
        containers: [],
        bl_id:null
    };

    componentDidMount() {
        let containers = localStorage.getItem('containers');
        this.setState({containers, isLoading:false});
        const {params} = this.props.match;
        if (typeof params.id !== 'undefined') {
           this.setState({bl_id:params.id, isLoading:false});
        }
    };

    onSubmit = (data) => {
        axios.post(API_URL + 'user/booking/draft_bl', data)
            .then((response) => {
                if (response.data.status === 'success') {
                    localStorage.removeItem('containers');
                    Storage.setFlash('success_message', response.data.message);
                    this.props.history.push("/bl/history");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    render() {

        return this.state.isLoading ? (
            <div className="loading"/>
        ) : (
            <Fragment>
                <div className="heading-bill-lading">
                    <h1>Draft BL</h1>
                </div>
                <Row>
                        <BLForm
                            containers={this.state.containers}
                            bl_id={this.state.bl_id}
                            onSubmit={this.onSubmit}/>
                </Row>
            <NotificationContainer/>
            </Fragment>
        );
    }
}

export default draftBLPage;
