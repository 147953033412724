import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {loginUser, loginUserFailure, logoutUser, showAuthModal} from "../../redux/auth/actions";
import {Col, Row} from "reactstrap";
import {SIGN_IN_MODE, FORGOT_PASS_MODE, SIGN_UP_MODE} from "../../constants/actionTypes";
import ButtonLoader from "../../util/ButtonLoader";
import Auth from "../../util/auth.service";

class LoginForm extends Component {

    state = {
        formMode: SIGN_IN_MODE, // show 1-login form, 2-forgot password form
        email: '',
        password: '',
        forgotPasswordError: '',
        forgotPasswordLoading: false
    };

    toggleFormMode = () => {
        let {formMode} = this.state;
        formMode = formMode === SIGN_IN_MODE ? FORGOT_PASS_MODE : SIGN_IN_MODE;
        let password = '';
        this.setState({formMode, password});
    };

    toggleModalMode = () => {
        this.props.showAuthModal(SIGN_UP_MODE);
    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    };

    handleLogin = () => {
        if (this.state.email && this.state.password) {
            this.props.loginUser(this.state, this.props.history);
            // this.setState({error_message: '', email: '', password: ''});
        } else {
            this.props.loginUserFailure('Please provide your credentials');
        }
    };

    handleForgotPassword = () => {
        if (this.state.email) {
            this.setState({forgotPasswordLoading: true});
            Auth.forgotPassword(this.state.email)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            forgotPasswordError: 'Reset password link sent to registered email',
                            email: ''
                        });
                    } else {
                        this.setState({error_message: response.data.message});
                    }
                    this.setState({forgotPasswordLoading: false});
                })
                .catch(err => this.setState({forgotPasswordLoading: false}));
        } else {
            this.setState({forgotPasswordError: 'Please provide a valid email address'});
        }
    };

    handleOnKeyUp = (e) => {
        if (e.key === 'Enter') {
            if (this.state.formMode === SIGN_IN_MODE) {
                this.handleLogin();
            } else {
                this.handleForgotPassword();
            }
        }
    };

    render() {
        return (
            <Row>
                {this.state.formMode === SIGN_IN_MODE ?
                    <Fragment>
                        <div className="auth-error">&nbsp;&nbsp; {this.props.auth.error_message} &nbsp;&nbsp;</div>
                        <Col lg="12" sm="12">
                            <span className="input input--nao">
                                <input className="input__field input__field--nao" type="text" name="email" id="input-1"
                                       onChange={this.handleInputChange} onKeyUp={this.handleOnKeyUp}/>
                                <label className="input__label input__label--nao" htmlFor="input-1">
                                    <span className="input__label-content input__label-content--nao">
                                        Email
                                    </span>
                                </label>
                                <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                     preserveAspectRatio="none">
                                    <path
                                        d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                                </svg>
                            </span>
                        </Col>

                        <Col lg="12" sm="12">
                            <span className="input input--nao">
                                <input className="input__field input__field--nao" type="password" name="password"
                                       onChange={this.handleInputChange} onKeyUp={this.handleOnKeyUp}/>
                                <label className="input__label input__label--nao" htmlFor="input-1">
                                    <span className="input__label-content input__label-content--nao">
                                        Password
                                    </span>
                                </label>
                                <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                     preserveAspectRatio="none">
                                    <path
                                        d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                                </svg>
                            </span>
                        </Col>


                        <Col lg="12" sm="12">
                            <div className="submit-btn sign-in-btn">
                                <button onClick={this.handleLogin}>
                                    <ButtonLoader isLoading={this.props.auth.loading} label={"Sign In"}/>
                                </button>
                            </div>
                        </Col>

                        {/*<Col lg="12" sm="12">*/}
                        {/*    <div className="forgot-password">*/}
                        {/*        <button onClick={this.toggleFormMode}>Forgot Password?</button>*/}
                        {/*        <button onClick={this.toggleModalMode}>Don't have and account? Sign up</button>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                            <div className="forgot-password">
                                <button onClick={this.toggleFormMode}>Forgot Password?</button>
                                <button onClick={this.toggleModalMode}>Don't have and account? Sign up</button>
                            </div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className="auth-error">&nbsp;&nbsp; {this.state.forgotPasswordError} &nbsp;&nbsp;</div>
                        <Col lg="12" sm="12">
                            <span className="input input--nao">
                                <input className="input__field input__field--nao" type="text" name="email"
                                       onChange={this.handleInputChange} onKeyUp={this.handleOnKeyUp}/>
                                <label className="input__label input__label--nao" htmlFor="input-1">
                                    <span className="input__label-content input__label-content--nao">
                                        Email
                                    </span>
                                </label>
                                <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                     preserveAspectRatio="none">
                                    <path
                                        d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                                </svg>
                            </span>
                        </Col>


                        <Col lg="12" sm="12">
                            <div className="submit-btn forgot-password-btn">
                                <button onClick={this.handleForgotPassword}>
                                    <ButtonLoader isLoading={this.state.forgotPasswordLoading} label={"Submit"}/>
                                </button>
                            </div>
                        </Col>

                        <Col lg="12" sm="12">
                            <div className="forgot-password">
                                <button onClick={this.toggleFormMode}>Know your Password?</button>
                            </div>
                        </Col>
                    </Fragment>
                }
            </Row>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {loginUser, logoutUser, loginUserFailure, showAuthModal}
)(LoginForm);
// export default LoginForm;