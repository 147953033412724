import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    UPDATE_USER,
    LOGOUT_USER_SUCCESS,
    USER_LOGIN_REQUIRED,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILURE,
    SHOW_AUTH_MODAL,
    HIDE_AUTH_MODAL,
    SIGN_IN_MODE
} from 'constants/actionTypes';
import Auth from 'util/auth.service';

const INIT_STATE = {
    user: Auth.user,
    loading: false,
    error_message: '',
    show_auth_modal: false,
    modal_mode: SIGN_IN_MODE,
    is_authenticated: Auth.check,
    registerLoading: false,
    registerMessage: '',
    registerError: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {...state, loading: true, error_message: ''};
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                show_auth_modal: false,
                user: action.payload,
                is_authenticated: true,
                error_message: ''
            };
        case LOGIN_USER_FAILURE:
            return {...state, loading: false, error_message: action.payload};
        case UPDATE_USER:
            return {...state, loading: false, show_auth_modal: false, user: action.payload, error_message: ''};
        case REGISTER_USER:
            return {...state, registerLoading: true};
        case REGISTER_USER_SUCCESS:
            return {...state, registerLoading: false, registerMessage: action.payload, registerError: ''};
        case REGISTER_USER_FAILURE:
            return {...state, registerLoading: false, registerError: action.payload, registerMessage: ''};
        case LOGOUT_USER_SUCCESS:
            return {...state, user: null, is_authenticated: false, loading: false};
        case USER_LOGIN_REQUIRED:
            return {...state,
                user: null,
                is_authenticated: false,
                loading: false,
                error_message: action.payload,
                show_auth_modal: true,
                modal_mode: SIGN_IN_MODE
            };
        case SHOW_AUTH_MODAL:
            return {...state, show_auth_modal: true, modal_mode: action.payload, error_message: ''};
        case HIDE_AUTH_MODAL:
            return {...state, show_auth_modal: false};
        default:
            return {...state};
    }
}
