import React, {Component} from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row, Label, Button} from "reactstrap";
import boatIcon from "../../assets/images/login-boat.png";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";

class ChangePasswordModal extends Component {

    state = {
        email: '',
        current_password: '',
        new_password: '',
        confirm_password: '',
        forgotPasswordLoading: false
    };

    onSubmit = (event, errors, values) => {
        if (errors.length === 0) {
            let data = {
                current_password: values.current_password,
                password: values.new_password,
                password_confirmation: values.confirm_password,
            };
            this.props.changePassword(data);
        }
    };

    render() {
        return (
            <Modal className="pop-up-login" isOpen={this.props.showChangePasswordModal} toggle={this.toggleModal}>

                <ModalHeader toggle={this.toggleModal}/>

                <ModalBody className="sign-up-form">
                    <Row>
                        <Col lg="5" sm="5" className="p-right-0">
                            <div className="text-model">
                                <h1>Get real-time online global freight rates instantly.</h1>
                                <p>From anywhere, to everywhere.</p>
                                <img src={boatIcon} alt=""/>
                            </div>
                        </Col>

                        <Col lg="7" sm="7" className="p-left-0">
                            <div className="form-model change-pass">
                                <h1>Change Password</h1>
                                <AvForm onSubmit={this.onSubmit}>
                                    <form className="inn-form">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <AvGroup className="error-t-negative">
                                                        <Label>Current Password</Label>
                                                        <AvField name="current_password" type="password"
                                                                 value=""
                                                                 validate={{
                                                                     required: {
                                                                         value: true,
                                                                         errorMessage: 'Please enter current password'
                                                                     }
                                                                 }}/>
                                                    </AvGroup>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <AvGroup className="error-t-negative">
                                                        <Label>New Password</Label>
                                                        <AvField name="new_password" type="password"
                                                                 value=""
                                                                 validate={{
                                                                     required: {
                                                                         value: true,
                                                                         errorMessage: 'Please enter New Password'
                                                                     }
                                                                 }}/>
                                                    </AvGroup>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <AvGroup className="error-t-negative">
                                                        <Label>Confirm Password</Label>
                                                        <AvField name="confirm_password" type="password"
                                                                 value=""
                                                                 validate={{
                                                                     required: {
                                                                         value: true,
                                                                         errorMessage: 'Please enter Confirm Password'
                                                                     },
                                                                     match: {
                                                                         value: 'new_password',
                                                                         errorMessage: 'Please enter valid Confirm Password'
                                                                     }
                                                                 }}/>
                                                    </AvGroup>
                                                </div>
                                            </div>
                                        </div>
                                        <Button color="secondary"
                                                outline
                                                onClick={this.props.handleChangePassword}>
                                            Cancel
                                        </Button>
                                        <Button color="primary">
                                            Submit
                                        </Button>
                                    </form>
                                </AvForm>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

        );
    }
}

export default ChangePasswordModal;