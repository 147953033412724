import React, {Component} from 'react';
import {Link, withRouter, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import mouseTrap from "react-mousetrap";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import {loginUser, logoutUser, loginUserFailure, showAuthModal, hideAuthModal} from "redux/actions";
import logo from '../../assets/images/fg-logo.png';
import AuthModal from "./AuthModal";
import Request from "../../util/api.service";
import {API_URL} from "../../constants/apiConstants";
import warningIconCircle from "../../assets/images/warning-icon-circle.png";
import BellIcon from "../../assets/images/bell.svg";
import ChangePasswordModal from "../../components/auth/changePasswordModal";
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Auth from "../../util/auth.service";
import {isMobile} from "../../util/util.service";
import PerfectScrollbar from "react-perfect-scrollbar";
import {companyName} from "../../constants/defaultValues";

class Header extends Component {

    state = {
        isModalOpen: false,
        isNavOpen: false,
        countries: [],
        showChangePasswordModal: false,
        form13NotificationDropDownOpen: false,
        form13Notifications: []
    };

    componentDidMount() {
        console.log('Jenkins Test 13-10-2020 1:00 PM');
        // this.bindShortcuts();
        if (this.props.auth.is_authenticated) {
            this.getNotifications();

            this.interval = setInterval(async () => {
                this.getNotifications();
            }, 30000)
        }
        Request.get(`${API_URL}/master/country`)
            .then((response) => {
                this.setState({countries: response.data.data});
            }).catch(err => err);
    };

    componentWillUnmount() {
        /* stop the notification update timer before leaving this page */
        clearTimeout(this.interval);
    }

    getNotifications = () => {
        axios.get(`${API_URL}/notifications`)
            .then(response => {
                let notificationResponse = response.data.data;
                let form13Notifications = [];
                for (let notification of notificationResponse) {
                    if (notification.data.type === "form_13") {
                        form13Notifications.push(notification);
                    }
                }
                this.setState({form13Notifications});
            })
            .catch(error => error);
    };

    getForm13NotificationView = (notification) => {
        axios.get(`${API_URL}/notifications/` + notification.id)
            .then(response => {
                let notificationResponse = response.data.data;
                let form13Notifications = [];
                for (let notification of notificationResponse) {
                    if (notification.data.type === "form_13") {
                        form13Notifications.push(notification);
                    }
                }
                this.setState({form13Notifications});
            })
            .catch(error => error);
        if (notification.data.type === 'form_13') {
            this.props.history.push('/booking/' + notification.data.booking_id + '/view');
            this.toggleForm13NotificationDropDown();
        }
    };

    toggleForm13NotificationDropDown = () => {
        this.setState({form13NotificationDropDownOpen: !this.state.form13NotificationDropDownOpen});
    }

    toggle = () => {
        this.setState({isNavOpen: !this.state.isNavOpen});
    };

    handleDashboard = () => {
        this.props.history.push('/dashboard');
    };

    handleKYC = () => {
        this.props.history.push('/kyc/upload');
    };

    handleChangePassword = () => {
        this.setState({showChangePasswordModal: !this.state.showChangePasswordModal});
        console.log('handleChangePassword');
    };

    handleLogout = () => {
        this.props.logoutUser(this.props.history);
    };

    bindShortcuts = () => {
        this.props.bindShortcut('h', () => {
            this.props.history.push('/booking/history');
        });
        this.props.bindShortcut('s', () => {
            this.props.history.push('/');
        });
        this.props.bindShortcut('p', () => {
            this.props.history.push('/user/profile');
        });
        this.props.bindShortcut('d', () => {
            this.props.history.push('/dashboard');
        });
        this.props.bindShortcut('?', () => {
            console.log('help page');
        });
        this.props.bindShortcut('/', () => {
            console.log('quick action');
        });
    };

    changePassword = (data) => {
        axios.post(API_URL + 'user/change-password', data)
            .then((response) => {
                if (response.data.status === "success") {
                    NotificationManager.success('Success message', response.data.message);
                    this.setState({showChangePasswordModal: !this.state.showChangePasswordModal});
                } else {
                    NotificationManager.warning('warning message', response.data.message);
                }

            })
            .catch((error) => {
                console.log(error);
            })
    };

    render() {

        let user = Auth.user;
        let customClass = (!isMobile() && !this.props.auth.is_authenticated) ? "custom-login-class auth-buttons, menu-responsive" : "auth-buttons, menu-responsive";
        return (
            <div className="menu-full">
                <Navbar className="fixed-top" color="light" light expand="md">

                    <NavbarToggler onClick={this.toggle}/>
                    <Link to={"/"} className="navbar-brand">
                        <img src={logo} style={{maxWidth: "10"}} alt={companyName}/>
                    </Link>
                    <Collapse isOpen={this.state.isNavOpen} navbar>
                        <Nav className="mr-auto" navbar>

                        </Nav>


                    </Collapse>
                    {this.props.auth.is_authenticated ?
                        <div className={"menu-responsive"}>
                            {/*<Link to="/user" className="btn btn-outline-success signup-btn my-2 my-sm-0">
                                    {this.props.auth.user.first_name} <i className="fas fa-caret-down"/>
                                </Link>*/}

                            <div className="bell-icon-header">
                                <ul>
                                    <li>
                                        <UncontrolledDropdown isOpen={this.state.form13NotificationDropDownOpen}
                                                              toggle={this.toggleForm13NotificationDropDown}
                                                              className="dropdown-menu-right">
                                            <DropdownToggle
                                                className="header-icon notificationButton" color="empty">
                                                <a href="#"> <img src={BellIcon} className="img-fluid" title="bell"
                                                                  alt="bell"/>
                                                    {this.state.form13Notifications.length}
                                                </a>
                                            </DropdownToggle>
                                            <DropdownMenu
                                                className="position-absolute mt-3 scroll"
                                                right
                                                id="notificationDropdown">
                                                <PerfectScrollbar
                                                    options={{suppressScrollX: true, wheelPropagation: false}}>
                                                    {this.state.form13Notifications.map((n, index) => {
                                                        return (
                                                            <div key={index}
                                                                 className="d-flex flex-row mb-3 pb-3 border-bottom"
                                                            >
                                                                <div className="pl-3 pr-2 notification-div">
                                                                    <div
                                                                        onClick={() => this.getForm13NotificationView(n)}>
                                                                        <p className="font-weight-medium mb-1">
                                                                            {'Form 13 Status has been changed for booking ' + n.data.book_id}
                                                                        </p>
                                                                        <p className="text-muted mb-0 text-small">
                                                                            {n.created_at}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </PerfectScrollbar>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </li>
                                </ul>
                            </div>
                            <UncontrolledDropdown className="btn btn-outline-success signup-btn my-2 my-sm-0"
                                                  nav inNavbar>
                                <DropdownToggle nav>
                                    <i className="fa fa-user-circle"/>
                                    <span> Hi,</span> {this.props.auth.user.first_name}&nbsp;
                                    <i className="fa fa-sort-down i-new"/>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={this.handleKYC} >
                                        Complete KYC
                                        {user && user.company.account_details && user.company.account_details.gst ? "" :
                                            <img src={warningIconCircle} className="img-fluid pending-icon"
                                                 title="Profile is incomplete" alt="Pending"/>
                                        }
                                    </DropdownItem>
                                    <DropdownItem onClick={this.handleDashboard}>
                                        Dashboard
                                    </DropdownItem>
                                    {/*<DropdownItem divider/>*/}
                                    <DropdownItem onClick={this.handleChangePassword}>
                                        Change Password
                                    </DropdownItem>
                                    {/*<DropdownItem divider/>*/}
                                    <DropdownItem onClick={this.handleLogout}>
                                        Logout
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                        :
                        <div className={customClass}>
                            <NavLink to="/register"
                                     className="btn btn-outline-success signup-btn my-2 my-sm-0 right-button">Signup
                            </NavLink>
                        </div>
                    }
                    {/*<div>
                                <button onClick={() => this.props.showAuthModal(SIGN_IN_MODE)}
                                        className="btn btn-outline-success signup-btn my-2 my-sm-0 left-button">Login
                                </button>
                                <button onClick={() => this.props.showAuthModal(SIGN_UP_MODE)}
                                        className="btn btn-outline-success signup-btn my-2 my-sm-0 right-button">Signup
                                </button>
                            </div>*/}
                </Navbar>
                {!this.props.auth.is_authenticated ?
                    <div>
                        <AuthModal countries={this.state.countries} history={this.props.history}/>
                    </div>
                    :
                    <div>
                        <ChangePasswordModal showChangePasswordModal={this.state.showChangePasswordModal}
                                             changePassword={this.changePassword}
                                             handleChangePassword={this.handleChangePassword}/>
                        <NotificationContainer/>
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};
export default withRouter(connect(
    mapStateToProps,
    {loginUser, logoutUser, loginUserFailure, showAuthModal, hideAuthModal}
)(mouseTrap(Header)));
