import React, {Component, Fragment} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert, CustomInput} from "reactstrap";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import Select from "react-select";
import Auth from "../../util/auth.service";
import {AWS_S3_URL} from "../../constants/apiConstants";
import {contactType} from "../../constants/contactType";
import Common from "../../util/common.service";
import {maxUploadFileSize} from "../../constants/defaultValues";
import ButtonLoader from "../../util/ButtonLoader";
import CustomFeedback from "../formValidations/CustomFeedback";


class MemberModal extends Component {

    state = {
        modalOpen: false,
        isAdmin: 0,
        action: true,
        profileLogoName: 'Only PDF, JPEG, PNG files accepted',
        profileLogoError: null,
        profileLogo: null,
        countrySelectError: null,
        stateSelectError: null,
        citySelectError: null,
        address: [],
        countryList: [],
        cityList: [],
        stateList: [],
        country: null,
        state: null,
        city: null
    };

    componentDidMount() {
        let state = null, city = null, country = null, stateList = this.props.stateList, cityList = this.props.cityList;
        if (this.props.member && this.props.member.state) {
            state = {
                label: this.props.member.state.name,
                value: this.props.member.state.id,
                key: this.props.member.state.id
            };

            city = {
                label: this.props.member.city.name,
                value: this.props.member.city.id,
                key: this.props.member.city.id
            };

            country = {
                label: this.props.member.country.name,
                value: this.props.member.country.id,
                key: this.props.member.country.id
            }
        } else {
            country = this.props.country;
        }

        this.setState({
            countryList: this.props.countryList,
            stateList,
            cityList,
            modalOpen: this.props.modalOpen,
            member: this.props.member,
            isAdmin: (this.props.member && this.props.member.company_role) ? this.props.member.company_role : 0,
            action: this.props.action,
            state, city, country
        });
    };

    toggleAddModal = () => {
        this.props.toggleAddModal();
    };

    handleAdminDefault = (event) => {
        if (event.target.checked === false) {
            this.setState({isAdmin: 0});
        } else {
            this.setState({isAdmin: 1});
        }
    };

    handleChange = (selected, type, error) => {
        if (type === "country") {
            let stateList = [];
            Common.stateByCountry(selected.value)
                .then(response => {
                    let state_list = response.data.data;
                    for (let state in state_list) {
                        stateList.push({
                            label: state_list[state].name,
                            value: state_list[state].id,
                            key: state_list[state].id
                        })
                    }
                    this.setState({[type]: selected, stateList, state: null, city: null, [error]: null});
                });
        } else if (type === "state") {
            let cityList = [];
            Common.cityByState(selected.value)
                .then(response => {
                    let city_list = response.data.data;
                    for (let city in city_list) {
                        cityList.push({
                            label: city_list[city].name,
                            value: city_list[city].id,
                            key: city_list[city].id
                        })
                    }
                    this.setState({[type]: selected, cityList, city: null, [error]: null});
                });

        } else {
            this.setState({[type]: selected, [error]: null});
        }
    };

    getProfileLogo = () => {
        var company_logo = document.querySelector('#company_logo');
        if (company_logo.files[0]) {
            let allowed_extensions = ['pdf', 'jpeg', 'jpg', 'png'];
            let parts = company_logo.files[0].name.split('.');
            let file_extension = parts[parts.length - 1];
            let profileLogoName = "Only PDF, JPEG, PNG files accepted";
            let profileLogoError = null;
            let profileLogo = null;
            if (allowed_extensions.indexOf(file_extension.toLowerCase()) !== -1) {
                if (company_logo.files[0].size <= maxUploadFileSize) {
                    profileLogo = company_logo.files[0];
                    profileLogoName = company_logo.files[0].name;
                } else {
                    profileLogoError =
                        <UncontrolledAlert color="danger" className="small-alert">
                            The uploaded file is exceeding the maximum file size of 5 MB.
                        </UncontrolledAlert>;
                }
            } else {
                profileLogoError =
                    <UncontrolledAlert color="danger" className="small-alert">
                        Please select a valid document file
                    </UncontrolledAlert>;
            }
            this.setState({profileLogoName, profileLogo, profileLogoError});
        }
    };


    onSubmit = (event, errors, values) => {
        let countrySelectError = '',
            stateSelectError = '',
            citySelectError = '',
            profileLogoError = '';
        if (this.state.country === null) {
            countrySelectError = 'Please select Country';
            errors.push(countrySelectError);
        }
        if (this.state.state === null) {
            stateSelectError = 'Please select State';
            errors.push(stateSelectError);
        }
        if (this.state.city === null) {
            citySelectError = 'Please select City';
            errors.push(citySelectError);
        }
        console.log(this.state.member.photo);
        if ((this.state.member && !this.state.member.photo && this.state.profileLogo === null)) {
            profileLogoError = 'Please select Profile Logo';
            errors.push(profileLogoError);
        }
        this.setState({countrySelectError, stateSelectError, citySelectError, profileLogoError});

        if (errors.length === 0 && !this.props.modalLoading) {
            let data = {
                ...values,
                photo: this.state.profileLogo ? this.state.profileLogo : "",
                country_id: this.state.country.value,
                state_id: this.state.state.value,
                company_role: this.state.isAdmin,
                city_id: this.state.city.value,
                created_by: Auth.user.id,
                company_id: Auth.user.company.id
            };
            console.log(data);

            if (this.state.action) {
                this.props.addMember(data);
            } else {
                this.props.updateMember(data, this.state.member.id);
            }
        }
    };

    render() {
        let contactTypes = [<option value="">Select Contact Type</option>];
        for (let type in contactType) {
            contactTypes.push(<option value={type}>{contactType[type]}</option>);
        }
        return (
            <Fragment>

                <Modal
                    isOpen={this.state.modalOpen}
                    toggle={this.toggleAddModal}
                    wrapClassName="modal-right"
                    backdrop="static"
                    className="add-address-popoup"
                >
                    <ModalHeader toggle={this.toggleAddModal}>
                        {this.state.action ? <h2>Add Member</h2> : <h2>Edit Member</h2>}
                    </ModalHeader>
                    <AvForm onSubmit={this.onSubmit}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>First Name <span className="required">*</span></label>
                                            <AvField type="text" id="first_name" name="first_name"
                                                     value={this.state.member && this.state.member.first_name ? this.state.member.first_name : ""}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter First Name'
                                                         },
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>Last Name <span className="required">*</span></label>
                                            <AvField type="text" id="last_name" name="last_name"
                                                     value={this.state.member && this.state.member.last_name ? this.state.member.last_name : ""}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Last Name'
                                                         },
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>Email <span className="required">*</span></label>
                                            <AvField type="text" id="email" name="email"
                                                     value={this.state.member && this.state.member.last_name ? this.state.member.email : ""}
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter Email ID'
                                                         },
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <AvGroup>
                                            <label>Mobile </label>
                                            <AvField type="text" id="mobile" name="mobile"
                                                     value={this.state.member && this.state.member.mobile ? this.state.member.mobile : ""}
                                                     validate={{
                                                         minLength: {
                                                             value: 10,
                                                             errorMessage: 'Please enter valid Mobile Number.'
                                                         },
                                                         maxLength: {
                                                             value: 15,
                                                             errorMessage: 'Please enter valid Mobile Number.'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>Country <span className="required">*</span></label>
                                        <Select
                                            className="home-select"
                                            name="country"
                                            value={this.state.country}
                                            placeholder="Select Country"
                                            noOptionsMessage={() => "Start typing country name"}
                                            onChange={(selected) => this.handleChange(selected, 'country', 'countrySelectError')}
                                            options={this.state.countryList}
                                        />
                                        <CustomFeedback>{this.state.countrySelectError}</CustomFeedback>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>State <span className="required">*</span></label>
                                        <Select
                                            className="home-select"
                                            name="state"
                                            value={this.state.state}
                                            placeholder="Select State"
                                            onChange={(selected) => this.handleChange(selected, 'state', 'stateSelectError')}
                                            options={this.state.stateList}
                                        />
                                        <CustomFeedback>{this.state.stateSelectError}</CustomFeedback>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label>City <span className="required">*</span></label>
                                        <Select
                                            className="home-select"
                                            name="city"
                                            value={this.state.city}
                                            placeholder="Select City"
                                            onChange={(selected) => this.handleChange(selected, 'city', 'citySelectError')}
                                            options={this.state.cityList}
                                        />
                                        <CustomFeedback>{this.state.citySelectError}</CustomFeedback>
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className="form-group">
                                        <div className="upload-2-files">
                                            <div className="upload-btn-wrapper">
                                                <button className="btn">
                                                    <i className="fa fa-file-alt"/> Select File
                                                </button>
                                                <input type="file" name="company_logo"
                                                       id="company_logo"
                                                       onChange={this.getProfileLogo}/>
                                                {this.state.member && this.state.member.photo ?
                                                    <img style={{width: 50, height: 50}} alt={"profileLogo"}
                                                         src={AWS_S3_URL + this.state.member.photo}/> : ""
                                                }
                                                <p style={{fontSize: "13px"}}>{this.state.profileLogoName}</p>
                                                {this.state.profileLogoError}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <CustomInput
                                        type="checkbox"
                                        id="is_default"
                                        label="Make this user Admin"
                                        onChange={(event) => this.handleAdminDefault(event)}
                                        checked={this.state.isAdmin}/>
                                </div>

                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary">
                                <ButtonLoader isLoading={this.props.modalLoading} label={"Submit"}/>
                            </Button>
                            <Button color="secondary" outline onClick={this.toggleAddModal}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </AvForm>
                </Modal>
            </Fragment>
        );
    }

}

export default MemberModal;