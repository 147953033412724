import React, {Component, Fragment} from 'react';
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {companyName} from "../../constants/defaultValues";

class Footer extends Component {

    render() {
        return (
            <Fragment>
                <div className="footer-last">
                    <div className="container-fluid">
                        <Row>

                            <Col lg="4" sm="4" className="fotter-no-re">
                                <div className="footer-last">
                                </div>
                            </Col>
                            <Col lg="12" sm="12">

                                <p>{(new Date().getFullYear())} © Copyright {companyName}. All rights reserved.</p>
                                <ul>
                                    <li><Link to="">Terms of Use</Link></li>
                                    <li><Link to="">Disclaimer</Link></li>
                                </ul>
                            </Col>

                        </Row>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Footer;