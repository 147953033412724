import React, {Component, Fragment} from "react";
import {Button} from 'reactstrap';
import 'react-notifications/lib/notifications.css';
import MemberCard from "../../../components/member/MemberCard";
import {fetchMembers} from "../../../redux/member/actions";
import {connect} from "react-redux";
import MemberModal from "../../../components/member/MemberModal";
import Common from "../../../util/common.service";
import {NotificationManager} from 'react-notifications';
import Member from "../../../util/member.service";

class MemberDetails extends Component {

    state = {
        countryList: [],
        stateList: [],
        cityList: [],
        country: null,
        modalOpen: false,
        modalLoading: false,
        member: [],
        action: true
    };

    componentDidMount() {
        this.props.fetchMembers();
        this.getCountryDetails();
    };

    getCountryDetails = () => {
        Common.getAllCountry()
            .then(response => {
                let country_list = response.data.data, countryList = [], selectedCountry = null;
                for (let country in country_list) {
                    if (country_list[country].id === 96) {
                        selectedCountry = {
                            label: country_list[country].name,
                            value: country_list[country].id,
                            key: country_list[country].id
                        }
                    }
                    countryList.push({
                        label: country_list[country].name,
                        value: country_list[country].id,
                        key: country_list[country].id
                    })
                }
                this.setState({countryList, country: selectedCountry}, this.getStateDetails);
            })
            .catch(error => error);
    };

    getStateDetails = () => {
        let stateList = [];
        Common.stateByCountry(this.state.country.value)
            .then(response => {
                let state_list = response.data.data;
                for (let state in state_list) {
                    stateList.push({
                        label: state_list[state].name,
                        value: state_list[state].id,
                        key: state_list[state].id
                    })
                }

                this.setState({stateList});
            });
    }

    editMember = (id) => {
        let member = this.props.members.list.find(member => member.id === id);
        let stateList = [];
        Common.stateByCountry(member.country_id)
            .then(response => {
                let state_list = response.data.data;
                for (let state in state_list) {
                    stateList.push({
                        label: state_list[state].name,
                        value: state_list[state].id,
                        key: state_list[state].id
                    })
                }
                this.setState({});
            }).catch(error => error);

        let cityList = [];
        Common.cityByState(member.state_id)
            .then(response => {
                let city_list = response.data.data;
                for (let city in city_list) {
                    cityList.push({
                        label: city_list[city].name,
                        value: city_list[city].id,
                        key: city_list[city].id
                    })
                }
            });
        this.setState({ cityList, city: null,  member, stateList,modalOpen: !this.state.modalOpen, action: false});

    };

    toggleAddModal = () => {
        this.setState({modalOpen: !this.state.modalOpen, action: true, member: [], cityList:[]});
    };

    addMember = (data) => {
        if(this.state.modalLoading === false){
            this.setState({modalLoading: true});
            Member.add(data)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        this.props.fetchMembers();
                        NotificationManager.success('Success message', response.data.message);
                    }else{
                        this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                        NotificationManager.warning('Warning message', response.data.message);
                    }
                })
                .catch(error => error);
        }
    };

    updateMember = (data, id) => {
        this.setState({modalLoading: true});
        Member.update(id, data)
            .then(response => {
                if (response.data.status === "success") {
                    this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                    NotificationManager.success('Success message', response.data.message);
                    this.props.fetchMembers();
                }else{
                    this.setState({modalOpen: !this.state.modalOpen, modalLoading: false});
                    NotificationManager.warning('Warning message', response.data.message);
                }
            })
            .catch(error => error);
    };

    removeMember = (id) => {
        if (window.confirm("Are you sure you want to delete this Team Member?")) {
        Member.delete(id)
            .then(response => {
                if (response.data.status === "success") {
                    this.props.fetchMembers();
                    NotificationManager.success('Success message', response.data.message);
                }
            })
            .catch(error => error);
        }
    };

    render() {
        return (
            <Fragment>
                <div className="add-line-bg">
                    <h1>Team Members</h1>
                </div>
                {this.state.modalOpen ?
                    <MemberModal
                        member={this.state.member}
                        countryList={this.state.countryList}
                        stateList={this.state.stateList}
                        country={this.state.country}
                        cityList={this.state.cityList}
                        modalOpen={this.state.modalOpen}
                        action={this.state.action}
                        modalLoading={this.state.modalLoading}
                        toggleAddModal={this.toggleAddModal}
                        addMember={this.addMember}
                        updateMember={this.updateMember}/>
                    : ""}
                {!this.props.members.loading ?
                    <div className="address-add-view">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="add-card" onClick={this.toggleAddModal}>
                                    <Button
                                        color="primary"
                                        size="md"
                                        className="top-right-button">
                                        <div className="add-btn-icon">
                                            <i className="fa fa-user-plus"/>
                                        </div>
                                        <span>Add New </span>
                                    </Button>
                                </div>
                            </div>
                            {this.props.members.list.map((member, key) =>
                                <MemberCard key={key}
                                            member={member}
                                            editMember={this.editMember}
                                            removeMember={this.removeMember}/>
                            )}
                        </div>
                    </div>
                    :
                    <h2 className={"loading-text"}>Loading...</h2>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = ({members}) => {
    return {members};
}

export default connect(mapStateToProps, {fetchMembers})(MemberDetails);