import React, {Component} from 'react';
import {connect} from "react-redux";
import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {SIGN_UP_MODE, SIGN_IN_MODE} from "../../constants/actionTypes";
import {loginUser, logoutUser, loginUserFailure, showAuthModal, hideAuthModal} from "../../redux/actions";
import LoginForm from "../../components/auth/LoginForm";
import SignupForm from "../../components/auth/SignupForm";
import loginboat from "../../assets/images/login-boat.png";

class AuthModal extends Component {

    state = {
        signUpDetails: {
            companyType: 0 // 0-shipper, 1-forwarder
        }
    };

    toggleModalMode = () => {
        let {modal_mode} = this.props.auth;
        modal_mode = modal_mode === SIGN_IN_MODE ? SIGN_UP_MODE : SIGN_IN_MODE;
        this.props.showAuthModal(modal_mode);
    };

    toggleModal = () => {
        if (this.props.auth.show_auth_modal) {
            this.props.hideAuthModal();
        } else {
            this.props.showAuthModal();
        }
    };

    render() {

        return (
            <Modal className="pop-up-login" isOpen={this.props.auth.show_auth_modal} toggle={this.toggleModal}>

                <ModalHeader toggle={this.toggleModal}/>

                <ModalBody className="sign-up-form">
                    <Row>
                        <Col lg="5" sm="5" className="p-right-0 respopnone">
                            <div className="text-model">
                                <h1>Get real-time online global freight rates instantly.</h1>
                                <p>From anywhere, to everywhere.</p>
                                <img src={loginboat} alt=""/>
                            </div>
                        </Col>

                        <Col lg="7" sm="7" className="p-left-0">

                            <div className="form-model">
                                <Row>
                                    <h1>
                                        Sign {this.props.auth.modal_mode === SIGN_UP_MODE ? 'Up' : 'In'}
                                        <span>/</span>
                                        <span onClick={this.toggleModalMode}>{this.props.auth.modal_mode === SIGN_UP_MODE ? 'sign in' : 'sign up'}</span>
                                    </h1>
                                </Row>
                                <div className="">
                                    {this.props.auth.modal_mode === SIGN_UP_MODE ?
                                        <SignupForm countries={this.props.countries}/>
                                        :
                                        <LoginForm history={this.props.history}/>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {loginUser, logoutUser, loginUserFailure, showAuthModal, hideAuthModal}
)(AuthModal);