import React, {Component, Fragment} from 'react';
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import {Label, Button} from "reactstrap";
import {NavLink} from "react-router-dom";
import {loginUser, loginUserFailure} from "../../../redux/auth/actions";
import {connect} from "react-redux";
import Request from "../../../util/api.service";
import {API_URL} from "../../../constants/apiConstants";
import {NotificationContainer, NotificationManager} from "react-notifications";

class ForgotPassword extends Component {

    state = {};

    componentDidMount() {

    }

    onSubmit = (event, errors, values) => {
        if (errors.length === 0) {
            let data = {
                ...values
            }
            Request.post(API_URL + '/user/forgot-password', data).then((response) => {
                console.log(response);
                if (response.data.status === "success") {
                    NotificationManager.success('Success message', response.data.message);
                }else{
                    NotificationManager.error('Error message', response.data.message);
                }
            }).catch(error => error);
            this.props.loginUser(values, this.props.history);
        }
    };

    render() {

        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                <div className="col-sm-8 col-lg-6 offset-lg-3 offset-sm-2">
                <div className="logi-register-all-fwrgt">
                    <div className="register-section login-section">
                        <AvForm onSubmit={this.onSubmit}>
                            <AvGroup className="error-t-negative">
                                <Label>Email</Label>
                                <AvField name="email" type="text"
                                         value={this.state.email}
                                         onChange={this.handleChange}
                                         validate={{
                                             required: {
                                                 value: true,
                                                 errorMessage: 'Please enter Email Id'
                                             }
                                         }}/>
                            </AvGroup>

                            <div className="login-btn">
                                <NavLink to={`/login`}>
                                    Login
                                </NavLink>
                                <Button color="primary">
                                    Submit
                                </Button>

                            </div>
                        </AvForm>
                    </div>
                </div>
                    </div>
                    </div>
                </div>
                <NotificationContainer/>
            </Fragment>
        )
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {loginUser, loginUserFailure}
)(ForgotPassword);

