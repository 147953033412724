import React, {Component, Fragment} from "react";
import {Col, Row} from "reactstrap";
import formIcon from "../../assets/images/form-icon.png";
import {Link} from "react-router-dom";
import {getBookingNote} from "../../util/util.service";
import {PENDING_VGM, UPDATE_VGM} from "../../constants/bookingStatus";

class HistoryCard extends Component {

    render() {
        const className = this.props.is_active ? "all-box active-booking" : "all-box";
        const pending_vgm = this.props.bookingDetails.containers.filter(container => !container.vgm_done_at).length;
        let booking_note = getBookingNote(this.props.bookingDetails.booking_status, {pending_vgm});

        if (this.props.bookingDetails.booking_status === UPDATE_VGM || this.props.bookingDetails.booking_status === PENDING_VGM) {
            let isBL = true;
            this.props.bookingDetails.containers.filter(container => {
                if (container.bl_id === null) {
                    isBL = false;
                }
            });
            if (isBL) {
                booking_note = "BL draft Uploaded";
            }
        }

        return (
            <Fragment>
                <Link to={"/booking/" + this.props.bookingDetails.id + "/view"}>
                    <div className={className}>
                        <div className="booking-bl">
                            <h3> Shipment Ref No <b>{this.props.bookingDetails.book_id}</b></h3>
                            <Row>
                                <Col lg="4" sm="4" className="pr-0">
                                    <div className="booking-name">
                                        <span>{(this.props.bookingDetails.pol.country) ? this.props.bookingDetails.pol.country.name : ""}</span>
                                        <b className="port-name">{this.props.bookingDetails.pol.port_name}</b>
                                        <ul>
                                            <li>
                                                <p>ETD</p>&nbsp;
                                                <b>{this.props.bookingDetails.etd_pol ? (this.props.bookingDetails.etd_pol).format("ddd, DD MMM'YY") : "NA"}</b>
                                            </li>
                                            <li>
                                                <p>Cut off</p>&nbsp;
                                                <b>{this.props.bookingDetails.cut_off_date ? (this.props.bookingDetails.cut_off_date).format("ddd, DD MMM'YY") : "NA"}</b>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg="4" sm="4">
                                    <div className="boat-day">
                                        <img src={formIcon} alt=""/>
                                        <p>{this.props.bookingDetails.transit_time ? this.props.bookingDetails.transit_time + ' Days' : 'NA'} </p>
                                    </div>
                                </Col>
                                <Col lg="4" sm="4" className="pl-0">
                                    <div className="booking-name">
                                        <span>{(this.props.bookingDetails.pod.country) ? this.props.bookingDetails.pod.country.name : ""}</span>
                                        <b className="port-name">{this.props.bookingDetails.pod.port_name}</b>
                                        <ul>
                                            <li>
                                                <p>ETA</p>&nbsp;
                                                <b>{(this.props.bookingDetails && this.props.bookingDetails.eta_pod) ? (this.props.bookingDetails.eta_pod).format("ddd, DD MMM'YY") : 'NA'}</b>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col lg="12" sm="12">
                                <div className="booking-highlight">
                                    <p>{booking_note}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Link>
            </Fragment>
        );
    }
}

export default HistoryCard;