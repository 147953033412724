let base_url = (typeof process.env.REACT_APP_API_URL !== 'undefined') ?
    process.env.REACT_APP_API_URL :
    'http://uatapi.freightgain.com/';

export const API_URL = base_url;
export const USER_OBJECT_NAME = 'auth-user';
export const LOGIN_URL = `${API_URL}user/login`;
export const LOGOUT_URL = `${API_URL}user/logout`;
export const REGISTER_URL = `${API_URL}user/register`;
export const FORGOT_PASSWORD_URL = `${API_URL}user/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}user/reset-password`;

export const AUTH_TOKEN_NAME = 'auth-token';
export const AWS_S3_URL = 'https://fg-equator.s3.us-west-2.amazonaws.com/';