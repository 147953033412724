import Auth from "./auth.service";
import {AUTH_TOKEN_NAME, REDIRECT_URL} from "../constants/actionTypes";
import Storage from "./storage.service";
import {history} from "./history";
import {store} from "../redux/store";
import {loginRequired} from "../redux/auth/actions";

// request interceptor function
export const requestInterceptor = (request) => {
    if (Auth.token) {
        request.headers[AUTH_TOKEN_NAME] = Auth.token;
    }
    return request
};

// response interceptor function for error
export const errorHandler = (error) => {
    if (error.response.status === 401 && error.response.data.status === "token") {
        Auth.logout();
        Storage.setFlash('error_message', 'Session expired, please login again.');
        Storage.setFlash(REDIRECT_URL, history.location.pathname);
        history.push('/');
        store.dispatch(loginRequired('Your session expired, please login again to view the requested page.'));
    }
    return Promise.reject({...error});
};

// response interceptor function for success
export const successHandler = (response) => {
    return response
};