import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Request from "../../../util/api.service";
import Auth from "../../../util/auth.service";
import {API_URL, AWS_S3_URL} from "../../../constants/apiConstants";
import {Col, Row} from "reactstrap";
import {updateUser} from "../../../redux/actions";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import ButtonLoader from "../../../util/ButtonLoader";
import {NotificationManager, NotificationContainer} from "react-notifications";

class KycDetails extends Component {
    state = {
        buttonLoading: false,
        gstDetails: [],
        companyDetails: [],

    };

    componentDidMount() {
        let user = Auth.user;
        let gstDetails = user.company.gst ? user.company.gst.details : [];
        this.setState({gstDetails, companyDetails: user.company});
    }

    onSubmit = (event, errors, values) => {
        if (errors.length === 0) {
            this.setState({buttonLoading: true});
            Request.get(API_URL + '/user/profile/gst-details/' + values.gst_no).then(response => {
                if (response.data.status === "success") {
                    Auth.user = response.data.data;
                    this.props.updateUser(response.data.data);
                    this.setState({gstDetails: response.data.data.company.gst.details});
                } else {
                    NotificationManager.warning('Warning message', response.data.message);
                }
                this.setState({buttonLoading: false});
            }).catch(error => error);
        }
        console.log(values);
    };

    render() {
        let utilityDocs = null, panCard = null, gstFile = null;

        if (this.state.companyDetails.kyc_documents) {
            if (this.state.companyDetails.kyc_documents['pan_card']) {
                let fileName = this.state.companyDetails.kyc_documents['pan_card'].split("/");
                panCard = <a href={AWS_S3_URL + this.state.companyDetails.kyc_documents['pan_card']}
                             target="_blank"
                             rel="noopener noreferrer">
                    <img alt={""}/>{fileName[fileName.length - 1]}
                </a>;
            }
            if (this.state.companyDetails.kyc_documents['gst_file']) {
                let fileName = this.state.companyDetails.kyc_documents['gst_file'].split("/");
                gstFile = <a href={AWS_S3_URL + this.state.companyDetails.kyc_documents['gst_file']}
                             target="_blank"
                             rel="noopener noreferrer">
                    <img alt={""}/>{fileName[fileName.length - 1]}
                </a>;
            }

            if (this.state.companyDetails.kyc_documents['utility_bill']) {
                utilityDocs = [];
                for (let doc in this.state.companyDetails.kyc_documents['utility_bill']) {
                    var fileName = this.state.companyDetails.kyc_documents['utility_bill'][doc].split("/");
                    utilityDocs.push(<li key={doc}>
                        <a href={AWS_S3_URL + this.state.companyDetails.kyc_documents['utility_bill'][doc]}
                           target="_blank"
                           rel="noopener noreferrer">
                            <img alt={""}/>{fileName[fileName.length - 1]}
                        </a>
                    </li>);
                }
            }

        }

        return (
            <Fragment>
                <div className="">
                    <div className="add-line-bg">
                        <h1>KYC</h1>
                    </div>
                    <br/>
                    <Row style={{marginTop: "2%"}}>
                        <Col lg="4" sm="4">
                            <AvForm onSubmit={this.onSubmit}>
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <AvGroup className="mb-0">
                                            <AvField type="text" name="gst_no" id="gst_no" value=""
                                                     placeholder="Enter GST Number"
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'Please enter GST Number.'
                                                         }
                                                     }}/>
                                        </AvGroup>
                                    </div>
                                    <div className="col-lg-6 col-sm-6">
                                        <button className="btn primary-btn" id="submit">
                                            <ButtonLoader color="primary"
                                                          isLoading={this.state.buttonLoading}
                                                          label="Submit"/>
                                        </button>
                                    </div>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Legal Name</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails['lgnm'] : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>GST No</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails['gstin'] : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>GST Address</p>
                                <b>{this.state.gstDetails && this.state.gstDetails['pradr'] && this.state.gstDetails['pradr']['addr'] ? this.state.gstDetails['pradr']['addr']['bnm'] + ', ' + this.state.gstDetails['pradr']['addr']['st'] + ', ' + this.state.gstDetails['pradr']['addr']['loc'] + ', ' + this.state.gstDetails['pradr']['addr']['bno'] + ', ' + this.state.gstDetails['pradr']['addr']['stcd'] + ', ' + this.state.gstDetails['pradr']['addr']['dst'] + ', ' + this.state.gstDetails['pradr']['addr']['flno'] + ', ' + this.state.gstDetails['pradr']['addr']['pncd'] : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Type</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.dty : ""}</b>
                            </div>
                        </Col>
                        <Col lg="4" sm="4">
                            <div className="cro-dive-box-details new-mt">
                                <p>Trade Name</p>
                                <b>{this.state.gstDetails ? this.state.gstDetails.tradeNam : ""}</b>
                            </div>
                        </Col>
                    </Row>
                    {this.state.companyDetails.kyc_documents ?
                        <Row>
                            <Col lg="4" sm="4">
                                <div className="cro-dive-box-details new-mt">
                                    <p>Pan Card</p>
                                    <b>{panCard}</b>
                                </div>
                            </Col>

                            <Col lg="4" sm="4">
                                <div className="cro-dive-box-details new-mt">
                                    <p>GST File</p>
                                    <b>{gstFile}</b>
                                </div>
                            </Col>
                            <Col lg="4" sm="4">
                                <div className="cro-dive-box-details new-mt">
                                    <p>Utility Bills</p>
                                    <ul>{utilityDocs}</ul>
                                </div>
                            </Col>
                        </Row> : null
                    }
                </div>
                <NotificationContainer/>
            </Fragment>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {updateUser}
)(KycDetails);


