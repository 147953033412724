import React, {Component, Fragment} from 'react';
import {Route, Switch} from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import {NotificationContainer} from "react-notifications";
import Request from "../../../util/api.service";
import {API_URL} from "../../../constants/apiConstants";

class UserAuth extends Component {

    state = {
        country_list: [],
        isLoading: true
    }

    componentDidMount() {
        Request.get(`${API_URL}master/country`)
            .then((response) => {
                this.setState({country_list: response.data.data, isLoading: false});
            }).catch(err => err);
    }

    render() {
        const {match} = this.props;

        return !this.state.isLoading ?
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12">
                            <div className="logi-register-all">
                                <div className="row">
                                    <Switch>
                                        <Route path={`${match.url}/login`} component={Login}/>
                                        <Route path={`${match.url}/register`} component={Register}/>
                                    </Switch>
                                    <div className="col-sm-6 col-lg-6">
                                        <div className="login-section">
                                            <h5>Login</h5>
                                            <Login history={this.props.history}/>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-lg-6 border-div">
                                        <div className="register-section login-section">
                                            <h5>Register</h5>
                                            <Register history={this.props.history}
                                                      country_list={this.state.country_list}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationContainer/>
            </Fragment>
            : <Fragment/>
    }
}

export default UserAuth;
