import React, {Component} from "react";
import {Col} from "reactstrap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {fetchEnquiries, refreshEnquiries} from "../../../redux/enquiry/actions";
import HistoryCard from "../../../components/enquiry/HistoryCard";

class SideNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enquiries: this.props.enquiries,
        }
    }

    componentDidMount() {
        if (!this.props.enquiries.loaded && !this.props.enquiries.is_loading) {
            this.props.refreshEnquiries();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.enquiries.list.length !== this.props.enquiries.list.length) {
            this.setState({enquiries: this.props.enquiries});
        }
    }

    getEnquiryListHtml = () => {
        let result_html = [], counter = 0;
        if (this.props.enquiries.is_loading) {
            result_html = (
                <h2 className={"loading-text-small"}>
                    Loading Enquiries...
                </h2>
            );
        } else if (this.state.enquiries.list.length === 0) {
            result_html = (
                <h2 style={{textAlign: 'center', width: '100%'}}>
                    <br/>
                  {/*  Sorry, no results found.*/}
                </h2>
            );
        } else {
            for (let enquiry of this.state.enquiries.list) {
                result_html.push(<HistoryCard
                    enquiryDetails={enquiry}
                    key={counter++}
                    isActive={enquiry.id === this.state.enquiries.activeEnquiry}/>);
            }
        }
        return result_html;
    };

    render() {
        return (
            <Col lg="4" sm="4">
                <div className="enquiry-add-btn">
                    <a color="primary" href="/add-enquiry">Add Enquiry</a>
                </div>
                <div className="serach-box-booking ">
                    <div className="search-input-file-cng">

                    </div>
                    <div className="serach-box-booking-full-scroll ">
                        {this.getEnquiryListHtml()}
                    </div>
                </div>
            </Col>
        );
    }
}

const mapStateToProps = ({enquiries}) => {
    return {enquiries};
};

export default withRouter(
    connect(
        mapStateToProps,
        {fetchEnquiries, refreshEnquiries}
    )(SideNav)
);