import React, {Component, Fragment} from 'react';
import {Route, Switch} from "react-router-dom";
import Login from "./Login";
import Register from "./Register";

class UserAuth extends Component {

    render() {
        const {match} = this.props;
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-8 col-xs-12 offset-sm-2">
                            <div className="logi-register-all">
                                <Switch>
                                    <Route path={`${match.url}/register`} component={Register}/>
                                </Switch>

                                <div className="login-section">
                                    <h5>Login</h5>
                                    <Login history={this.props.history}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default UserAuth;
