import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Col, Row} from "reactstrap";
import Auth from "../../../util/auth.service";

class setPassword extends Component {

    constructor() {
        super();
        this.state = {
            token: '',
            reset_success: false,
            isLoading: false,
            error_message: '',
            password: '',
            password_confirmation: ''
        };
    };

    componentDidMount() {
        let {params} = this.props.match;
        this.setState({token: params.token});
    }

    componentWillUnmount() {

    };

    handleInputChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    resetPassword = () => {
        let error = false, error_message = '';
        if (!this.state.password) {
            error = true;
            error_message = 'Please enter password';
        } else if (this.state.password.length < 6) {
            error = true;
            error_message = 'Password should be at least 6 characters long';
        } else if (this.state.password !== this.state.password_confirmation) {
            error = true;
            error_message = 'Confirm password does not match';
        }
        if (error) {
            this.setState({error_message});
        } else {
            this.setState({error_message: ''});
            let {password, password_confirmation, token} = this.state;
            Auth.resetPassword({password, password_confirmation, token})
                .then(response => {
                    return response.data
                })
                .then(response => {
                    // console.log(response);
                    if (response.status === 'success') {
                        this.setState({reset_success: true});
                    } else {
                        this.setState({error_message: response.message});
                    }
                })
                .catch(err => err);
        }
    };


    render()
    {
        let success_message = this.state.reset_success ?
            (<div style={{fontSize: '14px', color: 'green'}}>
                <div>Password Set successfully, click <Link to='/'>here</Link> to login</div>
                <br/>
            </div>) : '';

        let error_message = this.state.error_message ?
            (<div style={{fontSize: '12px', color: 'red'}}>
                {this.state.error_message}
            </div>) : '';

        return (
            <div className="container-fluid">
                <Row>
                    <Col lg="3" sm="3" className="reset-password form-model">
                        {success_message}
                        {error_message}
                        <h1>Set <span>Password</span></h1>
                        <span className="input input--nao">
                            <input className="input__field input__field--nao" type="password" name="password"
                                   id="input-1"
                                   onChange={this.handleInputChange} onKeyUp={this.handleOnKeyUp}/>
                            <label className="input__label input__label--nao" htmlFor="input-1">
                                <span className="input__label-content input__label-content--nao">
                                    Password
                                </span>
                            </label>
                            <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                 preserveAspectRatio="none">
                                <path
                                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                            </svg>
                        </span>
                        <span className="input input--nao">
                            <input className="input__field input__field--nao" type="password"
                                   name="password_confirmation" id="input-1"
                                   onChange={this.handleInputChange} onKeyUp={this.handleOnKeyUp}/>
                            <label className="input__label input__label--nao" htmlFor="input-1">
                                <span className="input__label-content input__label-content--nao">
                                    Confirm New password
                                </span>
                            </label>
                            <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                 preserveAspectRatio="none">
                                <path
                                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                            </svg>
                        </span>

                        <div className="search-btn-Reset ">
                            <button onClick={()=>this.resetPassword()} className="btn primary-btn">Set Password</button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default setPassword;