import axios from 'axios';
import {requestInterceptor, successHandler, errorHandler} from "./interceptors";
import {API_URL, AUTH_TOKEN_NAME} from "../constants/apiConstants";
import Auth from './auth.service';

// request interceptor
axios.interceptors.request.use(
    request => requestInterceptor(request)
);
// response interceptor
axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

export default class Request {

    static get base_url() {
        return API_URL;
    };

    static get = function (url) {
        let headers = [];
        headers[AUTH_TOKEN_NAME] = Auth.token;
        return axios.get(url, {
            headers: {...headers}
        });
    };

    static post = function (url, payload) {
        let body = new FormData();
        for (var key in payload) {
            body.append(key, payload[key]);
        }
        let headers = [];
        headers[AUTH_TOKEN_NAME] = Auth.token;
        return axios.post(url, body, {
            headers: {...headers}
        });
    };

    static put = function (url, payload) {
        return axios.put(url, payload);
    };

    static delete = function (url) {
        return axios.delete(url);
    };

    static downloadFile = (url, fileName) => {
        axios.get(url, {responseType:'blob'})
            .then((response) => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => error);
    }

}