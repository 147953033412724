import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {FETCH_BANKS} from "../../constants/actionTypes";
import {fetchBankSuccess} from "./actions";
import Bank from "../../util/bank.service";

const fetchBankAsync = async () => {
    return await Bank.history()
        .then(response => response.data)
        .catch(error => error);
};

function* fetchBanksFromApi() {
    try {
        const bank_data = yield call(fetchBankAsync);
        if (bank_data.status !== 'failed') {
            yield put(fetchBankSuccess(bank_data.data));
        }
    } catch (error) {

    }
}

export function* watchFetchBanks() {
    yield takeEvery(FETCH_BANKS, fetchBanksFromApi);
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchBanks)
    ]);
}