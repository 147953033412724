import React, {Component, Fragment} from 'react';
import Request from "../../util/api.service";
import {API_URL, AWS_S3_URL} from "../../constants/apiConstants";
import Select from "react-select";
import CustomFeedback from "../formValidations/CustomFeedback";
import {Button, Card, CardBody, CustomInput} from "reactstrap";
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import {maxForm13Limit} from "../../constants/defaultValues";
import {NotificationContainer} from "react-notifications";
import ButtonLoader from "../../util/ButtonLoader";
import {DRY, REFRIGERATED} from "../../constants/containerTypes";
import ContainerFields from "./ContainerFields";

class Form13 extends Component {

    state = {
        terminalList: [],
        bookingList: [],
        bookings: [],
        vesselData: [],
        bookingData: [],
        podData: [],
        bookingContainers: [],
        polValidationPorts: ['INMUN', 'INKAT'],
        containerSelected: [],
        vesselList: [],
        serviceList: [],
        podList: [],
        attachmentCount: [],
        removedAttachments: [],
        selectedCfsCode: null,
        cfsCodeError: null,
        containerError: null,
        isLoading: true,
        isoCodeList: [],
        originValue: null,
        odexPolCode: null,
        buttonLoading: false
    }

    componentDidMount() {
        this.getBookingDetails(this.props.form13Details);
        this.getCfsCodes();
        this.getIsoCodes();
    }

    getBookingDetails = (form13Details) => {
        let vesselData = form13Details.vesselData;
        let bookingData = form13Details.bookingData;
        let odexPolCode = form13Details.odex_pol_code;
        let podData = [];
        let selectedCfsCode = null;
        let selectedPod = null;
        let form13Data = bookingData.form13.length ? bookingData.form13[bookingData.form13.length - 1] : [];

        bookingData.form13Data = form13Data;
        if (form13Data.length && form13Data.details['cfs_code']) {
            let cfs_details = form13Data.details['cfs_code'];
            selectedCfsCode = {
                label: cfs_details.code + '-' + cfs_details.name,
                key: cfs_details.id,
                value: cfs_details.id,
                code: cfs_details.code
            }
        }

        for (let pod of form13Details.podData) {
            podData.push({label: pod.podNm, value: pod.podCd, key: pod.podCd});
        }
        if (form13Details.podData.length && form13Details.podData.length === 1) {
            selectedPod = {
                label: form13Details.podData[0].podNm,
                value: form13Details.podData[0].podCd,
                key: form13Details.podData[0].podCd
            }
        }

        this.setState({
            odexPolCode,
            selectedPod,
            vesselData,
            bookingData,
            podData,
            isLoading: false,
            selectedCfsCode,
            bookingContainers: bookingData.odex_containers
        });

        this.getForm13Documents(form13Data);
    };


    getCfsCodes = () => {
        Request.get(API_URL + 'cfs-code').then(response => {
            let cfs_codes = response.data.data;
            let CfsCodeList = [];
            if (cfs_codes) {
                for (let cfs_code of cfs_codes) {
                    CfsCodeList.push({
                        label: cfs_code.code + '-' + cfs_code.name,
                        key: cfs_code.id,
                        value: cfs_code.id,
                        code: cfs_code.code
                    });
                }
            }
            this.setState({CfsCodeList});
        }).catch(error => error);
    };

    getIsoCodes = () => {
        Request.get(API_URL + 'iso-code')
            .then(response => {
                this.setState({isoCodeList: response.data.data});
            }).catch(error => error);
    };

    handleChange = (name, selected, error) => {
        this.setState({[name]: selected, [error]: null});
    };

    getForm13Documents = (form13) => {
        let attachmentCount = [];
        if (form13 && form13.documents) {
            let i = 0;
            for (let document of form13.documents) {
                let name = "attachment_" + i;
                let attachment_link = "attachment_link_" + i;
                attachmentCount.push(i);
                this.setState({[name]: document.type, [attachment_link]: document.file});
                i++;
            }
        }
        this.setState({attachmentCount});
    };

    containerSelectHandler = (event, id) => {
        if (event.target.checked === true) {
            let containers = this.state.containerSelected;
            containers.push(id);
            this.setState({containerSelected: containers, containerError: null});
        } else {
            let containers = [];
            this.state.containerSelected.filter(function (value, index, arr) {
                if (value !== id) {
                    containers.push(value);
                }
            });
            this.setState({containerSelected: containers, containerError: null});
        }
    };

    getFilename = (file_input, validateMsg) => {
        var imagefile = document.querySelector('#' + file_input);
        if (imagefile.files[0]) {
            var parts = imagefile.files[0].name.split('.');
            let allowed_extensions = ['pdf', 'jpeg', 'jpg', 'png', 'xlsx', 'doc', 'docx'];
            if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                if (imagefile.files[0].size <= maxForm13Limit) {
                    this.setState({
                        [file_input]: imagefile.files[0] ? imagefile.files[0] : '',
                        [validateMsg]: null
                    });
                } else {
                    this.setState({[validateMsg]: "The uploaded file is exceeding the maximum file size of 5 MB."});
                }
            } else {
                this.setState({[validateMsg]: "Please select valid file."});
            }
        }
    };

    onSubmit = (event, errors, values) => {
        let cfsCodeError = null,
            containerError = null,
            podError = null;

        if (this.state.selectedCfsCode === null && this.state.originValue === "C") {
            cfsCodeError = "Please select CFS Code.";
            errors.push(cfsCodeError);
        }

        if (this.state.selectedPod === null) {
            podError = "Please select POD Port.";
            errors.push(podError);
        }

        if (!this.state.containerSelected.length) {
            containerError = "Please select at least one container";
            errors.push(containerError);
        }

        this.setState({
            containerError,
            cfsCodeError,
            podError
        });

        if (errors.length === 0) {

            let data = {
                ...values,
                ...this.state,
                removedAttachments: JSON.stringify(this.state.removedAttachments),
                shipping_line_code: this.state.vesselData.bnfCode,
                containers: this.state.containerSelected,
                via_no: this.state.vesselData.viaNo,
                service: this.state.vesselData.service,
                vessel: this.state.vesselData.vesselNm,
                terminal: this.state.vesselData.terminalCode,
                pol_port_code: this.state.odexPolCode,
                pod_port_code: this.state.selectedPod.value,
                cfs_code: this.state.selectedCfsCode ? this.state.selectedCfsCode.code : "",
                cfs_code_id: this.state.selectedCfsCode ? this.state.selectedCfsCode.key : "",
                booking_id: this.state.bookingData.id
            };
            this.props.onsubmit(data);
        }
    };

    addAttachment = () => {
        let attachmentCount = this.state.attachmentCount;
        if (attachmentCount.length) {
            let last = attachmentCount[attachmentCount.length - 1];
            if (last < 50) {
                attachmentCount.push(parseInt(last) + parseInt(1));
            }
        } else {
            attachmentCount.push(0);
        }
        this.setState({attachmentCount});
    };

    removeAttachment = (count, name) => {
        let attachmentCount = [];
        let removedAttachments = this.state.removedAttachments;
        this.state.attachmentCount.filter((value, i) => {
            if (value !== count) {
                attachmentCount.push(value);
            }
        });
        if (this.state['attachment_link_' + count]) {
            removedAttachments.push(count);
        }
        this.setState({attachmentCount, removedAttachments});
    };

    handleSelectAttachment = (event, count) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleOrigin = (event) => {
        this.setState({originValue: event.target.value});
    };

    render() {
        let attachmentFields = [];
        for (let count of this.state.attachmentCount) {
            attachmentFields.push(
                <div className="row">
                    <div className="col-md-6" key={count}>
                        <div className="cancel-btn-filed">
                            <div>
                                <AvGroup className="error-t-negative input-change">
                                    <AvField name={"attachment_" + count} type="select"
                                             value={this.state["attachment_" + count]}
                                             onChange={(event) => this.handleSelectAttachment(event, count)}
                                             validate={{
                                                 required: {
                                                     value: true,
                                                     errorMessage: 'Please select Attachment Type'
                                                 }
                                             }}>
                                        <option value="">Select Attachment</option>
                                        <option value="VGM_ANXR1">VGM-Annexure 1</option>
                                        <option value="DLVRY_ORDER">Delivery Order</option>
                                        <option value="CNTNR_LOAD_PLAN">Container Load Plan</option>
                                        <option value="INVOICE">Invoice</option>
                                        <option value="PACK_LIST">Packing List</option>
                                        <option value="CUSTOMS_EXAM_REPORT">Customs Examination Report</option>
                                        <option value="SHIP_BILL">Shipping Bill</option>
                                        <option value="CLN_CRTFCT">Cleaning Certificate</option>
                                        <option value="DG_DCLRTION">DG Declaration</option>
                                        <option value="BOOKING">Booking</option>
                                        <option value="COMPLETION_COPY_ANNEXURE">Completion Copy Annexure</option>
                                        <option value="PHOTO">Photo</option>
                                        <option value="SURVY_RPRT">Survey Report</option>
                                    </AvField>

                                </AvGroup>
                            </div>
                            <div className="cancel-btn-x">
                                <a onClick={() => this.removeAttachment(count, "attachment_" + count)}>
                                    <i className="fa fa-remove"
                                       aria-hidden="true"/>
                                </a>
                            </div>
                        </div>
                    </div>

                    {!this.state['attachment_link_' + count] ?
                        <div className="col-md-3 changes-type-im">
                            <input type="file" id={"attachment_doc_" + count} name={"attachment_doc_" + count}
                                   onChange={() => this.getFilename('attachment_doc_' + count, "attachment_doc_error_" + count)}/>
                            {this.state["attachment_doc_error_" + count]}
                        </div> : null
                    }

                    {this.state['attachment_link_' + count] ?
                        <div className="col-md-3 changes-type-im">
                            <ul className="cancel-btn-file">
                                <li>
                                    <a href={AWS_S3_URL + this.state['attachment_link_' + count]}
                                       target="_blank" rel="noopener noreferrer">
                                     <span className="badge badge-pill handle mr-1">
                                    <i className="fa fa-eye" title="View File"/>
                                </span>
                                    </a>
                                </li>
                            </ul>
                        </div> : null
                    }

                </div>
            );
        }

        let containers = [];
        let sr_no = 0;
        for (let container in this.state.bookingContainers) {
            containers.push(<tr key={container}>
                <td>
                    <CustomInput
                        type="checkbox"
                        id={"container_" + container}
                        onChange={(event) => this.containerSelectHandler(event, this.state.bookingContainers[container].id)}
                        checked={this.state.containerSelected.includes(this.state.bookingContainers[container].id)}/>
                </td>
                <td>{this.state.bookingContainers[container].container_number}</td>
            </tr>);
        }


        return !this.state.isLoading ?
            <Fragment>
                <AvForm onSubmit={this.onSubmit}>

                    <Card>
                        <CardBody>
                            <div className="booking-view-text-full form-13-div">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12 col-12">
                                        <div className="vgm-section-heding">
                                            <h1>New Form 13</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>Shipping Line</b>
                                            <p>{this.state.bookingData.ship_line.ship_name}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>POL</b>
                                            <p>{this.state.bookingData.pol.port_name}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>Vessel Name</b>
                                            <p>{this.state.vesselData.vesselNm}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>VIA NO.</b>
                                            <p>{this.state.vesselData.viaNo}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>Terminal</b>
                                            <p>{this.state.vesselData.terminalCode}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>Service</b>
                                            <p>{this.state.vesselData.service}</p>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>POD</b>
                                            <p>{this.state.bookingData.pod.port_name}</p>
                                        </div>
                                    </div>*/}
                                    {(this.state.bookingData.pol.un_code && this.state.polValidationPorts.includes(this.state.bookingData.pol.un_code)) ?
                                        <div className="col-lg-3 col-sm-3">
                                            <label>Final POD</label>
                                            <AvField type="text" name="fpod"
                                                     value={this.state.bookingData && this.state.bookingData.form13Data && this.state.bookingData.form13Data.details ? this.state.bookingData.form13Data.details['fpod'] : ''}
                                                     validate={{
                                                         required: {
                                                             value: (this.state.bookingData.pol.un_code && this.state.polValidationPorts.includes(this.state.bookingData.pol.un_code)),
                                                             errorMessage: 'Please enter Final POD'
                                                         }
                                                     }}/>
                                        </div> : null}
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>cargo type</b>
                                            <p>{(this.state.bookingData.container_type_id === DRY && this.state.bookingData.is_haz === 0 ? "GEN" : (this.state.bookingData.container_type_id === REFRIGERATED ? "REF" : "HAZ"))}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-3">
                                        <label>POD</label>
                                        <Select
                                            className="home-select"
                                            name="cfs"
                                            value={this.state.selectedPod}
                                            placeholder="Select POD"
                                            noOptionsMessage={() => "Start typing POD"}
                                            onChange={(selected) => this.handleChange('selectedPod', selected, 'podError')}
                                            options={this.state.podData}
                                        />
                                        <CustomFeedback>{this.state.podError}</CustomFeedback>
                                    </div>

                                    <div className="col-lg-3 col-sm-3">
                                        <label>Origin</label>
                                        <AvField type="select" name="origin"
                                                 onChange={this.handleOrigin}
                                                 value={this.state.bookingData && this.state.bookingData.form13Data && this.state.bookingData.form13Data.details ? this.state.bookingData.form13Data.details['origin'] : ''}
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please select Origin'
                                                     }
                                                 }}>
                                            <option value="">SELECT</option> 
                                            <option value="B">BUFFER</option>
                                            <option value="C">DOCK STUFFED</option>
                                            <option value="F">FACTORY STUFFED</option>
                                            <option value="R">ICD BY ROAD</option>
                                            <option value="W">ON WHEEL CONTAINER</option>
                                            <option value="F_CFS">Factory Stuffed Via CFS</option>
                                            <option value="E_TANK">Empty Tank</option>
                                        </AvField>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <label>Shipping Instruction No.</label>
                                        <AvField type="text" name="shpInstructNo"
                                                 value={this.state.bookingData && this.state.bookingData.form13Data && this.state.bookingData.form13Data.details ? this.state.bookingData.form13Data.details['shipping_instruction_no'] : ''}
                                        />
                                    </div>

                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>Booking No.</b>
                                            <p>{this.state.bookingData.cro_no}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-3">
                                        <label>Container Status</label>
                                        <AvField type="select" name="container_status"
                                                 value={this.state.bookingData && this.state.bookingData.form13Data && this.state.bookingData.form13Data.details ? this.state.bookingData.form13Data.details['container_status'] : ''}
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please select Container Status'
                                                     }
                                                 }}>
                                            <option value="">SELECT</option>
                                            <option value="EMPTY">EMPTY(E)</option>
                                            <option value="FULL">FULL(F)</option>
                                        </AvField>
                                    </div>

                                    {this.state.originValue && this.state.originValue === "C" ?
                                        <div className="col-lg-3 col-sm-3">
                                            <label>CFS Code</label>
                                            <Select
                                                className="home-select"
                                                name="cfs"
                                                value={this.state.selectedCfsCode}
                                                placeholder="Select CFS Code"
                                                noOptionsMessage={() => "Start typing CFS Code"}
                                                onChange={(selected) => this.handleChange('selectedCfsCode', selected, 'cfsCodeError')}
                                                options={this.state.CfsCodeList}
                                            />
                                            <CustomFeedback>{this.state.cfsCodeError}</CustomFeedback>
                                        </div> : null
                                    }


                                    <div className="col-lg-12 col-sm-12 col-12">
                                        <div className="vgm-section-heding">
                                            <h1>Customer Information</h1>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-sm-3">
                                        <label>Issue To</label>
                                        <AvField type="select" name="issueTo"
                                                 value={this.state.bookingData && this.state.bookingData.form13Data && this.state.bookingData.form13Data.details ? this.state.bookingData.form13Data.details['issue_to'] : ''}
                                                 validate={{
                                                     required: {
                                                         value: true,
                                                         errorMessage: 'Please select Issue To'
                                                     }
                                                 }}>
                                            <option value="">SELECT</option>
                                            <option value="CHA">CHA</option>
                                            <option value="SHIPPER">SHIPPER</option>
                                        </AvField>
                                    </div>
                                    <div className="col-lg-3 col-sm-3 col-12">
                                        <div className="booking-view-text">
                                            <b>Shipper Name</b>
                                            <p>{this.state.bookingData.shipper ? this.state.bookingData.shipper.company_name : this.state.bookingData.user.company.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <div className="row">
                                {(this.state.bookingData.pol && this.state.polValidationPorts.includes(this.state.bookingData.pol.un_code)) ?
                                    <Fragment>
                                        <div className="col-lg-3 col-sm-3">
                                            <label>Terminal Login ID</label>
                                            <AvField type="text" name="terminal_login_id"
                                                     value={this.state.bookingData && this.state.bookingData.form13Data && this.state.bookingData.form13Data.details ? this.state.bookingData.form13Data.details['terminal_login_id'] : ''}
                                                     validate={{
                                                         required: {
                                                             value: (this.state.bookingData.pol && this.state.polValidationPorts.includes(this.state.bookingData.pol.un_code)),
                                                             errorMessage: 'Please enter Terminal Login ID'
                                                         }
                                                     }}/>
                                        </div>

                                        <div className="col-lg-3 col-sm-3">
                                            <label>Consignee Name</label>
                                            <AvField type="text" name="consignee_name"
                                                     value={this.state.bookingData && this.state.bookingData.form13Data && this.state.bookingData.form13Data.details ? this.state.bookingData.form13Data.details['consignee_name'] : ''}
                                                     validate={{
                                                         required: {
                                                             value: (this.state.bookingData.pol && this.state.polValidationPorts.includes(this.state.bookingData.pol.un_code)),
                                                             errorMessage: 'Please enter Consignee Name'
                                                         }
                                                     }}/>
                                        </div>

                                        <div className="col-lg-3 col-sm-3">
                                            <label>Consignee Address</label>
                                            <AvField type="text" name="consignee_addr"
                                                     value={this.state.bookingData && this.state.bookingData.form13Data && this.state.bookingData.form13Data.details ? this.state.bookingData.form13Data.details['consignee_addr'] : ''}
                                                     validate={{
                                                         required: {
                                                             value: (this.state.bookingData.pol && this.state.polValidationPorts.includes(this.state.bookingData.pol.un_code)),
                                                             errorMessage: 'Please enter Consignee Address'
                                                         }
                                                     }}/>
                                        </div>


                                        <div className="col-lg-12 col-sm-12">
                                            <label>Cargo Description</label>
                                            <AvField type="text" name="cargo_desc"
                                                     value={this.state.bookingData ? this.state.bookingData.goods_description : ""}
                                                     validate={{
                                                         required: {
                                                             value: (this.state.bookingData.pol && this.state.polValidationPorts.includes(this.state.bookingData.pol.un_code)),
                                                             errorMessage: 'Please enter Cargo Description'
                                                         }
                                                     }}/>
                                        </div>
                                    </Fragment> : null}

                            </div>

                        </CardBody>
                    </Card>


                    {containers && containers.length ?
                        <Card>
                            <CustomFeedback>  {this.state.containerError}</CustomFeedback>
                            <CardBody>
                                <div className="slide-table">
                                    <Card className="mb-5">
                                        <div className="col-lg-12 col-sm-12 col-12">
                                            <div className="vgm-section-heding">
                                                <h1>Container Details</h1>
                                            </div>
                                        </div>
                                        <CardBody>
                                            <table
                                                className="uk-table uk-table-hover  uk-table-middle uk-table-divider">
                                                <thead>
                                                <tr>
                                                    <th/>
                                                    <th className="uk-table-shrink">Container Number</th>
                                                    <th className="uk-table-shrink">Container Size</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.bookingContainers.map((container, index) =>
                                                    <Fragment>
                                                        <ContainerFields container={container}
                                                                         index={index}
                                                                         isoCodeList={this.state.isoCodeList}
                                                                         containerSelectHandler={this.containerSelectHandler}
                                                                         containerSelected={this.state.containerSelected}/>
                                                    </Fragment>
                                                )}
                                                </tbody>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </div>
                            </CardBody>
                        </Card>
                        : null}


                    <Card>
                        <CardBody>
                            <div className="col-lg-12 col-sm-12 col-12">
                                <div className="vgm-section-heding">
                                    <h1><i title="Add Attachment" className="fa fa-plus-circle"
                                           onClick={this.addAttachment}/> Attachment Details</h1>(Total Attachment
                                    size
                                    should not exceed 4 MB.)
                                    {attachmentFields}
                                </div>
                            </div>

                        </CardBody>
                    </Card>


                    <div className="col-md-12 ">
                        <div className="submit-btn-from-13">
                            <Button color="primary" id="submit_button">
                                <ButtonLoader color="primary"
                                              isLoading={this.props.buttonForm13Loading} label="Submit"/>

                            </Button>
                            &nbsp; &nbsp;
                            <Button color="warning" onClick={this.props.toggleForm13Modal}>
                                Cancel
                            </Button>
                        </div>
                    </div>

                </AvForm>
                <NotificationContainer/>
            </Fragment>
            :
            <Fragment>
                <NotificationContainer/>
            </Fragment>
    }
}

export default Form13;