import React, {Component, Fragment} from "react";
import {Col} from "reactstrap";
import {CardHeader, Table} from "reactstrap";
import {AvField, AvGroup} from "availity-reactstrap-validation";
import Request from "../../util/api.service";
import {API_URL} from "../../constants/apiConstants";
import {NotificationManager} from "react-notifications";
import collect from "collect.js";
import AddContainerModal from "./AddContainerModal";
import ContainerDetailSectionRow from "./ContainerDetailSectionRow";
import {REFRIGERATED} from "../../constants/containerTypes";


class ContainerDetailSection extends Component {

    state = {
        containerDetails: null,
        selectedContainers: [],
        removedContainers: [],
        total_volume: 0,
        volume: [],
        net_weight: [],
        cargo_weight: [],
        tare_weight: [],
        packages: [],
        total_net_wt: 0,
        totalCargoWeight: 0,
        totalTareWeight: 0,
        TotalNoPackages: 0,
        modalContainerOpen: false,
        container_count_20: 0,
        container_count_40: 0,
        container_count_40hc: 0,
        container_value_20: null,
        container_value_40: null,
        container_value_40hc: null
    }

    componentDidMount() {
        this.setState({
            selectedContainers: this.props.selectedContainers,
            containers: this.props.containers,
            containersDataOriginal: this.props.bookingDetails
        }, this.setContainerDetails);
    }

    setContainerDetails = () => {
        let containerDetails = [], tareWeight = 0, cargoWeight = 0, no_of_pkgs = 0, volume = [],
            packages = [],
            net_weight = [], cargo_weight = [], tare_weight = [], total_net_wt = 0, total_volume = 0;
        let container_count_20 = 0,
            container_count_40 = 0,
            container_count_40hc = 0;
        let container_value_20 = null,
            container_value_40 = null,
            container_value_40hc = null;

        let packageType = (this.state.selectedContainers[0].package && this.state.selectedContainers[0].package.name) ? this.state.selectedContainers[0].package.name : "Packages";


        this.state.selectedContainers.map((container, key) => {

            let container_size = (container.container_size === "40hc") ? (this.props.booking_details.container_type_id === REFRIGERATED ? '40 RF' : '40 HC') : container.container_size + (this.props.booking_details.container_type_id === REFRIGERATED ? ' RF' : ' FT');
            /*
            * reset container's count and freight charges of selected containers according to size.
            */
            if (container.package_type_id !== this.state.selectedContainers[0].package_type_id) {
                packageType = "Packages";
            }

            let charge = 'frt_' + container.container_size;
            if (container.container_size === "20") {
                container_count_20++
                container_value_20 = container.freight_charges[charge];
            } else if (container.container_size === "40") {
                container_count_40++;
                container_value_40 = container.freight_charges[charge];
            } else if (container.container_size === "40hc") {
                container_count_40hc++;
                container_value_40hc = container.freight_charges[charge];
            }
            /*
            * calculate selected container's weights
            */
            tareWeight = parseFloat(tareWeight) + ((container.tare_weight != null) ? parseFloat(container.tare_weight) : 0);
            cargoWeight = parseFloat(cargoWeight) + ((container.cargo_weight != null) ? parseFloat(container.cargo_weight) : 0);
            no_of_pkgs = parseFloat(no_of_pkgs) + ((container.no_of_pkgs != null) ? parseFloat(container.no_of_pkgs) : 0);
            total_net_wt = parseFloat(total_net_wt) + ((container.net_weight != null) ? parseFloat(container.net_weight) : 0);
            total_volume = parseFloat(total_volume) + ((container.volume != null) ? parseFloat(container.volume) : 0);

            volume[container.id] = container.volume;
            net_weight[container.id] = container.net_weight;
            packages[container.id] = container.no_of_pkgs;
            cargo_weight[container.id] = container.cargo_weight;
            tare_weight[container.id] = container.tare_weight;
            container.net_weight = net_weight[container.id];
            container.volume = volume[container.id];

            containerDetails.push(
                <ContainerDetailSectionRow
                    container={container}
                    container_size={container_size}
                    getTotalNetWeight={this.getTotalNetWeight}
                    getTotalVolume={this.getTotalVolume}
                    packageList={this.props.packageList}
                    removeContainer={this.removeContainer}
                    getTotalCargoWeight={this.getTotalCargoWeight}
                    getTotalTareWeight={this.getTotalTareWeight}
                    getTotalPackages={this.getTotalPackages}
                    handlePackageChange={this.handlePackageChange}
                    handleInputChangeVersion={this.props.handleInputChangeVersion}
                    bl_version={this.props.bl_version}
                    keyId={key}/>
            );

        });
        this.props.setParentState('TotalNoPackages', no_of_pkgs);
        this.props.setParentState('packageType', packageType);
        this.props.setParentState('totalCargoWeight', cargoWeight.toFixed(3));
        this.props.setParentState('totalNetWeight', total_net_wt.toFixed(3));
        this.props.setParentState('total_volume', total_volume.toFixed(2));


        this.setState({
            container_count_20,
            container_count_40,
            container_count_40hc,
            container_value_20,
            container_value_40,
            container_value_40hc,
            containerDetails,
            total_volume: total_volume.toFixed(2),
            total_net_wt: total_net_wt.toFixed(3),
            volume,
            net_weight,
            tare_weight,
            cargo_weight,
            packages,
            totalCargoWeight: cargoWeight.toFixed(3),
            totalTareWeight: tareWeight.toFixed(3),
            TotalNoPackages: no_of_pkgs
        });
    }

    getTotalNetWeight = (weight, containerId) => {
        let netWt = this.state.net_weight;
        netWt[containerId] = weight;
        let total_net = 0;
        for (let net of netWt) {
            if (!isNaN(parseInt(net))) {
                total_net = parseFloat(total_net) + parseFloat(net);
            }
        }
        this.props.setParentState('totalNetWeight', total_net.toFixed(3));
        this.setState({net_weight: netWt, total_net_wt: total_net.toFixed(3)});
    };

    getTotalPackages = (event, containerId) => {
        let packages = this.state.packages;
        packages[containerId] = event.target.value;
        let TotalNoPackages = 0;
        for (let pkg of packages) {
            if (!isNaN(parseInt(pkg))) {
                TotalNoPackages = parseFloat(TotalNoPackages) + parseFloat(pkg);
            }
        }
        this.props.setParentState('TotalNoPackages', TotalNoPackages.toFixed(3));
        this.setState({TotalNoPackages: TotalNoPackages.toFixed(3), packages});
        this.props.handleInputChangeVersion(event);
    }

    getTotalCargoWeight = (weight, containerId) => {

        let cargoWt = this.state.cargo_weight;
        cargoWt[containerId] = weight;
        let totalCargoWeight = 0;
        for (let cargo of cargoWt) {
            if (!isNaN(parseInt(cargo))) {
                totalCargoWeight = parseFloat(totalCargoWeight) + parseFloat(cargo);
            }
        }
        this.props.setParentState('totalCargoWeight', totalCargoWeight.toFixed(3));
        this.setState({cargo_weight: cargoWt, totalCargoWeight: totalCargoWeight.toFixed(3)});
    };

    getTotalTareWeight = (weight, containerId) => {

        let tareWt = this.state.tare_weight;
        tareWt[containerId] = weight;
        let totalTareWeight = 0;
        for (let cargo of tareWt) {
            if (!isNaN(parseInt(cargo))) {
                totalTareWeight = parseFloat(totalTareWeight) + parseFloat(cargo);
            }
        }
        this.setState({tare_weight: tareWt, totalTareWeight: totalTareWeight.toFixed(3)});
    };

    getTotalVolume = (event, containerId) => {
        let volume = this.state.volume;
        volume[containerId] = event.target.value;
        let total_volume = 0;
        for (let vol of volume) {
            if (!isNaN(parseInt(vol))) {
                total_volume = parseFloat(total_volume) + parseFloat(vol);
            }
        }
        this.props.setParentState('total_volume', total_volume.toFixed(2));
        this.setState({volume: volume, total_volume: total_volume.toFixed(2)});
    };

    handlePackageChange = (event, containerId) => {
        let pkg = event.target[event.target.selectedIndex].getAttribute('data-pkg');
        let packageType = this.props.packageType;
        let selectedContainers = [];
        let finalContainer = this.state.selectedContainers.find((container) => (container.id === containerId));
        finalContainer.package = {id: event.target.value, name: pkg};
        selectedContainers.push(finalContainer);

        this.state.selectedContainers.filter((cont) => {
            if (cont.id !== finalContainer.id) {
                selectedContainers.push(cont);
            }
        });

        packageType = pkg;
        this.state.selectedContainers.map((container, key) => {
            if(container.package){
                if (container.package.name !== pkg) {
                    packageType = "Packages";
                }
            }
        });
        this.setState({selectedContainers});
        this.props.setParentState('packageType', packageType);
        this.props.handleInputChangeVersion(event);
    };

    toggleAddModal = () => {
        let data = {
            pol_id: this.props.booking_details.pol.id,
            pod_id: this.props.booking_details.pod.id,
            shipline_id: this.props.booking_details.shipline_id,
            user_id: this.props.booking_details.user_id,
            freight_terms: this.props.booking_details.freight_terms,
            commodity_type_id: this.props.booking_details.commodity.id,
            pol_agent_company_id: this.props.booking_details.pol_agent_company_id,
        }
        Request.post(API_URL + 'user/draft_bl', data).then((response) => {
            if (response.data.status === "success") {
                let containersDataOriginal = [];
                /*
                * add freight charge against each container and add only those containers who has bl id null or who's bl id is same as current bl id.
                */
                for (let containerDetail of response.data.data) {
                    let isAvaliable = false;
                    for (let container of containerDetail.containers) {
                        let charge = 'frt_' + container.container_size;
                        container.freight_value = container.freight_charges[charge];
                        if (container.bl_id === null || this.state.containers.includes(parseInt(container.id)) || container.bl_id !== null && parseInt(container.bl_id) === parseInt(this.props.bl_id)) {
                            isAvaliable = true;
                        }
                    }
                    if (isAvaliable) {
                        if (containerDetail) {
                            containersDataOriginal.push(containerDetail);
                        }
                    }
                }

                this.setState({
                    containersDataOriginal
                }, this.handleContainerChange);
            } else {
                NotificationManager.warning('Warning message', response.data.message);
            }
        }).catch(error => error);
    };

    handleSelectedContainerChange = (key) => {

        let selectedContainers = [];
        let containersDataOriginal = [...this.state.containersDataOriginal];
        /*
        * get only selected containers list from all bookings.
        */
        for (let BookingData of containersDataOriginal) {
            let selectedContainer = collect(BookingData.containers);
            selectedContainer = selectedContainer.whereIn('id', this.state.containers);
            selectedContainer = selectedContainer.toArray();
            if (selectedContainer && selectedContainer[0]) {
                for (let container of selectedContainer) {
                    selectedContainers.push(container);
                }
            }
        }
        this.setState({selectedContainers}, () => this.updateContainerDetails(key));
    };

    updateContainerDetails = (ContainerKey) => {
        let containerDetails = [];
        /*
            * Calculate Net Weight (Remove net weight of container)
            */
        let totalTareWeight = this.state.totalCargoWeight, totalCargoWeight = this.state.totalCargoWeight,
            volume = this.state.volume,
            packages = this.state.packages,
            no_of_pkgs = this.state.no_of_pkgs,
            net_weight = this.state.net_weight, cargo_weight = this.state.cargo_weight,
            tare_weight = this.state.tare_weight, total_net_wt = parseFloat(this.state.total_net_wt),
            total_volume = this.state.total_volume;

        if (typeof ContainerKey !== 'undefined' && ContainerKey !== null) {
            net_weight = [];
            total_net_wt = 0;
            for (let net in this.state.net_weight) {
                if (parseInt(net) !== parseInt(ContainerKey)) {
                    net_weight[net] = this.state.net_weight[net];
                    total_net_wt = parseFloat(total_net_wt) + (this.state.net_weight[net] ? parseFloat(this.state.net_weight[net]) : 0);
                }
            }
            /*
            * Calculate Volume (Remove Volume of container)
            */
            volume = [];
            total_volume = 0;
            for (let vol in this.state.volume) {
                if (parseInt(vol) !== parseInt(ContainerKey)) {
                    volume[vol] = this.state.volume[vol];
                    total_volume = parseFloat(total_volume) + (this.state.volume[vol] ? parseFloat(this.state.volume[vol]) : 0);
                }
            }
            /*
            * Calculate Cargo Weight (Remove Cargo Weight of container)
            */
            cargo_weight = [];
            totalCargoWeight = 0;
            for (let cargo in this.state.cargo_weight) {
                if (parseInt(cargo) !== parseInt(ContainerKey)) {
                    cargo_weight[cargo] = this.state.cargo_weight[cargo];
                    totalCargoWeight = parseFloat(totalCargoWeight) + (this.state.cargo_weight[cargo] ? parseFloat(this.state.cargo_weight[cargo]) : 0);
                }
            }
            /*
            * Calculate Tare Weight (Remove Tare Weight of container)
            */
            tare_weight = [];
            totalTareWeight = 0;
            for (let tare in this.state.tare_weight) {
                if (parseInt(tare) !== parseInt(ContainerKey)) {
                    tare_weight[tare] = this.state.tare_weight[tare];
                    totalTareWeight = parseFloat(totalTareWeight) + (this.state.tare_weight[tare] ? parseFloat(this.state.tare_weight[tare]) : 0);
                }
            }
        }
        /*
       * Reset Container's count and charges.
       */
        no_of_pkgs = 0;
        let packageType = (this.state.selectedContainers[0].package && this.state.selectedContainers[0].package.name) ? this.state.selectedContainers[0].package.name : "Packages";

        let container_count_20 = 0,
            container_count_40 = 0,
            container_count_40hc = 0;
        let container_value_20 = null,
            container_value_40 = null,
            container_value_40hc = null;
        this.state.selectedContainers.map((container, key) => {
            let container_size = (container.container_size === "40hc") ? (this.props.booking_details.container_type_id === REFRIGERATED ? '40 RF' : '40 HC') : container.container_size + (this.props.booking_details.container_type_id === REFRIGERATED ? ' RF' : ' FT');
            /*
            * reset container's count and freight charges of selected containers according to size.
            */
             no_of_pkgs = parseFloat(no_of_pkgs) + parseFloat(container.no_of_pkgs);
            let charge = 'frt_' + container.container_size;
            if (container.container_size === "20") {
                container_count_20++
                container_value_20 = container.freight_charges[charge];
            } else if (container.container_size === "40") {
                container_count_40++;
                container_value_40 = container.freight_charges[charge];
            } else if (container.container_size === "40hc") {
                container_count_40hc++;
                container_value_40hc = container.freight_charges[charge];
            }
            if (container.package_type_id !== this.state.selectedContainers[0].package_type_id) {
                packageType = "Packages";
            }

            if (typeof ContainerKey === 'undefined') {
                totalTareWeight = 0;
                for (let tare of tare_weight) {
                    if (!isNaN(parseInt(tare))) {
                        totalTareWeight = parseFloat(totalTareWeight) + parseFloat(tare);
                    }
                }
                totalCargoWeight = 0;
                for (let cargo of cargo_weight) {
                    if (!isNaN(parseInt(cargo))) {
                        totalCargoWeight = parseFloat(totalCargoWeight) + parseFloat(cargo);
                    }
                }

                no_of_pkgs = parseFloat(no_of_pkgs) + parseFloat(container.no_of_pkgs);

                let total_net = 0;
                for (let net of net_weight) {
                    if (!isNaN(parseInt(net))) {
                        total_net = parseFloat(total_net) + parseFloat(net);
                    }
                }
                let total_volume = 0;
                for (let vol of volume) {
                    if (!isNaN(parseInt(vol))) {
                        total_volume = parseFloat(total_volume) + parseFloat(vol);
                    }
                }

            }

            container.net_weight = net_weight[container.id];
            container.volume = volume[container.id];

            containerDetails.push(
                <ContainerDetailSectionRow
                    container={container}
                    container_size={container_size}
                    getTotalNetWeight={this.getTotalNetWeight}
                    getTotalVolume={this.getTotalVolume}
                    packageList={this.props.packageList}
                    removeContainer={this.removeContainer}
                    getTotalCargoWeight={this.getTotalCargoWeight}
                    getTotalTareWeight={this.getTotalTareWeight}
                    getTotalPackages={this.getTotalPackages}
                    handlePackageChange={this.handlePackageChange}
                    handleInputChangeVersion={this.props.handleInputChangeVersion}
                    bl_version={this.props.bl_version}
                    keyId={key}/>
            );

        })
        this.props.setParentState('TotalNoPackages', no_of_pkgs);
        this.props.setParentState('packageType', packageType);
        this.props.setParentState('totalCargoWeight', totalCargoWeight);
        this.props.setParentState('totalNetWeight', total_net_wt);
        this.props.setParentState('total_volume', total_volume);

        this.setState({
            container_count_20,
            container_count_40,
            container_count_40hc,
            container_value_20,
            container_value_40,
            container_value_40hc,
            containerDetails,
            net_weight, total_net_wt: total_net_wt,
            volume, total_volume: total_volume,
            cargo_weight, totalCargoWeight: totalCargoWeight,
            tare_weight, totalTareWeight: totalTareWeight,
            TotalNoPackages: no_of_pkgs
        });

    }

    handleContainerChange = () => {
        let containersDataOriginal = [...this.state.containersDataOriginal];
        /*
        * get all other bookings except selected containers.
        */
        let containersData = [];
        for (let BookingData of containersDataOriginal) {
            let containersDetails = [...BookingData.containers];
            let containerData = [];
            containersDetails = collect(containersDetails);
            containersDetails = containersDetails.whereNotIn('id', this.state.containers);
            containersDetails = containersDetails.toArray();
            for (let container of containersDetails) {
                // if (container.bl_id === null) {
                containerData.push(container);
                //  }
            }
            if (containerData.length) {
                BookingData.non_bl_containers = containerData;
                containersData.push(BookingData);
            }

        }
        this.setState({
            containersDataCopy:containersData,
            containersData,
            modalContainerOpen: !this.state.modalContainerOpen
        }, this.updateContainerDetails);
    };

    filterByBookingNo = (booking_no) => {
        /*
        * Search by booking No.
        */
        let containersData = collect(this.state.containersDataCopy);
        if (booking_no !== "") {
            containersData = containersData.filter((value, key) => value.book_id.includes(booking_no.toUpperCase()));
        }
        containersData = containersData.toArray();
        this.setState({containersData});
    };

    cancelModal = () => {
        this.setState({modalContainerOpen: !this.state.modalContainerOpen}, this.updateContainerDetails);
    };

    removeContainer = (container_id, key) => {
        let containers = this.state.containers.filter(container => container !== container_id);
        /*
        * Atleast one container should be there and add the removed containers into separte list if it is already present into selected container's list.
        */
        if (containers.length >= 1) {

            let removedContainers = this.state.removedContainers;


            if (this.props.OriginalContainers) {
                if (this.props.OriginalContainers.includes(parseInt(container_id))) {
                    removedContainers.push(container_id);
                }
            }


            this.props.setParentState('containers', containers);
            this.props.setParentState('removedContainers', removedContainers);
            this.setState({
                removedContainers,
                containers
            }, () => this.handleSelectedContainerChange(container_id));

        } else {
            NotificationManager.warning('Warning message', "Atleast one Container should be there");
        }

    };

    setParentState = (name, value) => {
        this.setState({[name]: value});
    }

    addContainers = (addedContainers) => {
        let containers = addedContainers.concat(this.state.containers);
        /*
        * add newly added container into selected container's list and also check if that container is present in a removed containers if yes and then delete that container from removed container's list.
        */
        let removedContainers = []
        if (this.props.OriginalContainers) {
            this.state.removedContainers.filter(container => {
                if (!this.props.OriginalContainers.includes(parseInt(container))) {
                    removedContainers.push(container);
                }
            });
        }
        this.setState({
            removedContainers,
            containers,
            modalContainerOpen: !this.state.modalContainerOpen,
        }, () => this.handleSelectedContainerChange(null));
        this.props.setParentState('containers', containers);
        this.props.setParentState('removedContainers', removedContainers);

    };

    render() {
        return (
            <Fragment>
                <CardHeader className="custom-header card-purple-header align-items-center">
                    <div>
                        <h3 className="card-title card-custom-title colorBlue">Container Details</h3>
                    </div>
                </CardHeader>
                <div className="h-padTopBtm">

                    <div className="">

                        <Table bordered>
                            <thead>
                            <tr>
                                <th>Sr.No.</th>
                                <th>Container No. <span className="required">*</span></th>
                                <th>Container Type</th>
                                <th>Customs Seal <span className="required">*</span></th>
                                <th>Agent Seal <span className="required">*</span></th>
                                <th>Cargo Wt <span className="required">*</span></th>
                                <th>Tare Wt <span className="required">*</span></th>
                                <th>Net Wt <span className="required">*</span></th>
                                <th>Volume <span className="required">*</span></th>
                                <th>Pkg Type <span className="required">*</span></th>
                                <th>No of Units <span className="required">*</span></th>
                                <th>
                                    <i title="Add Container" className="fa fa-plus-circle"
                                       onClick={this.toggleAddModal}/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.containerDetails}
                            </tbody>
                        </Table>
                    </div>
                    <div className="row">
                        <Col lg="2" sm="2">
                            <AvGroup className="error-t-negative">
                                <label>Total Tare Wt(Kgs)</label>
                                <AvField name="tare_wt" id="tare_wt" type="text"
                                         value={this.state.totalTareWeight}
                                         readOnly/>
                            </AvGroup>
                        </Col>
                        <Col lg="2" sm="2">
                            <AvGroup className="error-t-negative">
                                <label>Total Cargo Wt(kgs)</label>
                                <AvField name="cargo_wt" id="cargo_wt" type="text"
                                         value={this.state.totalCargoWeight}
                                         readOnly/>
                            </AvGroup>
                        </Col>
                        <Col lg="2" sm="2">
                            <AvGroup className="error-t-negative">
                                <label>Total Net Wt(Kgs)</label>
                                <AvField name="net_wt" id="net_wt" type="text"
                                         value={this.state.total_net_wt}
                                         readOnly/>
                            </AvGroup>
                        </Col>
                        <Col lg="2" sm="2">
                            <AvGroup className="error-t-negative">
                                <label>Total Volume</label>
                                <AvField name="volume" id="volume" type="text"
                                         value={this.state.total_volume}
                                         readOnly/>
                            </AvGroup>
                        </Col>
                        <Col lg="2" sm="2">
                            <AvGroup className="error-t-negative">
                                <label>Total No. of Units</label>
                                <AvField name="ofunits" id="no.ofunits" type="text"
                                         value={this.state.TotalNoPackages}
                                         readOnly/>
                            </AvGroup>
                        </Col>
                    </div>
                </div>
                <AddContainerModal modalContainerOpen={this.state.modalContainerOpen}
                                   toggleAddModal={this.toggleAddModal}
                                   setParentState={this.setParentState}
                                   cancelModal={this.cancelModal}
                                   addContainers={this.addContainers}
                                   containersData={this.state.containersData}
                                   totalContainers={this.state.containers}
                                   bl_id={this.props.bl_id}
                                   container_count_20={this.state.container_count_20}
                                   container_count_40={this.state.container_count_40}
                                   container_count_40hc={this.state.container_count_40hc}
                                   container_value_20={this.state.container_value_20}
                                   container_value_40={this.state.container_value_40}
                                   container_value_40hc={this.state.container_value_40hc}
                                   filterByBookingNo={this.filterByBookingNo}
                />
            </Fragment>
        );
    }
}

export default ContainerDetailSection;