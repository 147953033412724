import React, {Component, Fragment} from 'react';
import {Row, Container, Col} from "reactstrap";
import {connect} from "react-redux";
import collect from "collect.js";

import {formatDate, formatRoute} from "../../../util/util.service";
import Booking from "../../../util/booking.service";
import Search from "../../../util/search.service";
import ModifySearch from "../../../components/search/ModifySearch";
import ResultRow from "../../../components/search/ResultRow";
import Storage from "../../../util/storage.service";

import recentBookingIcon from '../../../assets/images/booking-icon.png';
import bulkBooking from '../../../assets/images/grow-booking-icon.png';
import routesfoundicon from '../../../assets/images/routesfound-icon.png';
import routesfoundicon1 from '../../../assets/images/routesfound-icon1.png';
import {Link} from "react-router-dom";
import Select from "react-select";
import Enquiry from "../booking/enquiry";
import {NotificationContainer} from "react-notifications";
import {REFRIGERATED} from "../../../constants/containerTypes";

class SearchResults extends Component {

    state = {
        checked: false,
        queryParams: null,
        pol_port: null,
        pod_port: null,
        container_20ft: 0,
        container_40ft: 0,
        container_40hc: 0,
        sort_20ft: null,
        sort_40ft: null,
        sort_40hc: null,
        sortTransitTime: null,
        commodity_type: null,
        container_type: null,
        etd_pol: null,
        loadingResults: true,
        routes: [],
        originalRoutes: collect([]),
        shippingLines: [],
        filteredShippingLines: [],
        recentBookings: [],
        selectedFilterOption: null,
        isGetQuote: false
    };

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        if (!params.get('pol') || !params.get('pod') || !params.get('commodity')) {
            // if any of the 3 params not found in URL then redirect to home page
            Storage.setFlash('error_message', 'Invalid selection, please try gain');
            this.props.history.push('/');
        }
        let etd_pol;
        if (params.get('date')) {
            const minEtdPol = new Date();
            // minEtdPol.setDate(minEtdPol.getDate() + 2);
            etd_pol = new Date(params.get('date'));
            if (minEtdPol.getTime() > etd_pol.getTime()) {
                etd_pol = minEtdPol;
            }
        } else {
            etd_pol = new Date();
            etd_pol.setDate(etd_pol.getDate() + 2);
        }
        let containers = params.get('containers');
        let container_20ft = 0;
        let container_40ft = 0;
        let container_40hc = 0;
        if (containers) {
            containers = containers.split(',');
            container_20ft = containers[0];
            container_40ft = containers[1];
            container_40hc = containers[2];
        }
        let queryParams = {
            etd_pol,
            pol_un_code: params.get('pol'),
            pod_un_code: params.get('pod'),
            container_type: params.get('container_type'),
            containers: params.get('containers'),
            commodity_type_id: parseInt(params.get('commodity'))
        };
        let commodity_type_id = parseInt(params.get('commodity'));
        this.setState({etd_pol, commodity_type_id, queryParams, container_40ft, container_20ft, container_40hc});
        if (this.props.auth.is_authenticated) {
            Booking.recent()
                .then(response => {
                    let recentBookings = response.data.data;
                    this.setState({recentBookings});
                }).catch(err => err)
        }
    }

    handleFastestChange = (checked) => {
        this.setState({checked});
    };

    handleLowestChange = (checked) => {
        this.setState({checked});
    };

    setParentState = (key, value) => {
        this.setState({[key]: value});
    };

    getSearchResults = () => {
        this.setState({loadingResults: true, isGetQuote: false});
        let payload = {
            pol_id: this.state.pol_port.value,
            pod_id: this.state.pod_port.value,
            container_20ft: this.state.container_20ft,
            container_40ft: this.state.container_40ft,
            container_40hc: this.state.container_40hc,
            cargo_type: 1, // FCL
            container_type: this.state.commodity_type.container_type_id,
            commodity_type: this.state.commodity_type.value,
            cargo_readiness_date: formatDate(this.state.etd_pol),
        };

        Search.routes(payload)
            .then(response => {
                let routes = response.data.data;
                routes = this.rearrangeRoutes(routes);
                let shippingLines = collect(routes).pluck('carrier_name').unique().sort().all();
                this.setState({
                    originalRoutes: collect(routes),
                    routes,
                    shippingLines,
                    loadingResults: false
                });
            }).then(error => error);
    };

    filterRoutes = (shippingLine) => {
        let {filteredShippingLines, originalRoutes} = this.state;
        if (filteredShippingLines.indexOf(shippingLine) !== -1) {
            filteredShippingLines.splice(filteredShippingLines.indexOf(shippingLine), 1);
        } else {
            filteredShippingLines.push(shippingLine);
        }
        let routes = [];
        if (filteredShippingLines.length) {
            routes = originalRoutes.whereIn('carrier_name', filteredShippingLines).all();
        } else {
            routes = originalRoutes.all();
        }
        this.setState({filteredShippingLines, routes});
    };

    rearrangeRoutes = (rates) => {
        let route_list = [];
        for (let rate of rates) {
            rate = formatRoute(rate);
            route_list.push(rate);
        }
        return route_list;
    };

    filterRates = (name, charge, value) => {
        let route_collection = collect(this.state.routes);
        route_collection = route_collection[value](charge);
        route_collection = route_collection.toArray();
        this.setState({
            routes: route_collection,
            sort_20ft: null,
            sort_40ft: null,
            sort_40hc: null,
            sortTransitTime: null,
            [name]: value
        });
    };

    handleChange = (selected) => {
        let route_collection = collect(this.state.routes);
        route_collection = route_collection[selected.direction](selected.charge);
        route_collection = route_collection.toArray();
        this.filterRates(selected.key, selected.charge, selected.direction);
        this.setState({routes: route_collection, selectedFilterOption: selected});
    };

    handleGetQuote = () => {
        this.setState({isGetQuote: true});
    };

    render() {
        const FilterOptions = [
            {
                label: '20 FT Lowest',
                value: "1",
                key: "sort_20ft",
                charge: "freight_charges_20",
                direction: "sortBy"
            },
            {
                label: '20 FT Highest',
                value: "2",
                key: "sort_20ft",
                charge: "freight_charges_20",
                direction: "sortByDesc"
            },

            {
                label: '40 HC Lowest',
                value: "5",
                key: "sort_40hc",
                charge: "freight_charges_40hc",
                direction: "sortBy"
            },

            {
                label: '40 HC Highest',
                value: "6",
                key: "sort_40hc",
                charge: "freight_charges_40hc",
                direction: "sortByDesc"
            },
            {
                label: 'Min. Transit Time',
                value: "7",
                key: "sortTransitTime",
                charge: "transit_time",
                direction: "sortBy"
            },
            {
                label: 'Max. Transit Time',
                value: "8",
                key: "sortTransitTime",
                charge: "transit_time",
                direction: "sortByDesc"
            }
        ];

        if(this.state.container_type && parseInt(this.state.container_type.value) !== REFRIGERATED){
            FilterOptions.push( {
                label: '40 FT Highest',
                value: "4",
                key: "sort_40ft",
                charge: "freight_charges_40",
                direction: "sortByDesc"
            });

            FilterOptions.push( {
                label: '40 FT Lowest',
                value: "3",
                key: "sort_40ft",
                charge: "freight_charges_40",
                direction: "sortBy"
            });
        }

        return (
            <Fragment>
                <div className="menu-full co-p">
                    {this.state.queryParams ?
                        <ModifySearch
                            queryParams={this.state.queryParams}
                            setParentState={this.setParentState}
                            container_20ft={this.state.container_20ft}
                            container_40ft={this.state.container_40ft}
                            container_40hc={this.state.container_40hc}
                            getSearchResults={this.getSearchResults}
                            commodity_type_id={this.state.commodity_type_id}/> :
                        <Fragment/>}
                </div>

                {this.state.loadingResults ?
                    <Container>
                        <h1 style={{textAlign: 'center'}}>Loading...</h1>
                    </Container> :

                    <Container>
                        <Row>
                            <Col sm="3" lg="3">
                                {this.state.shippingLines.length ?
                                    <div className="shipping-line">
                                        <h1>Shipping Lines</h1>
                                        <ul>
                                            {this.state.shippingLines.map((shippingLine, key) => {
                                                return <li key={key}>
                                                    <label className="custom-control material-checkbox">
                                                        <input type="checkbox" className="material-control-input"
                                                               onChange={() => {
                                                                   this.filterRoutes(shippingLine)
                                                               }}/>
                                                        <span className="material-control-indicator"/>
                                                        <span
                                                            className="material-control-description">{shippingLine}</span>
                                                    </label>
                                                </li>;
                                            })}
                                        </ul>
                                    </div>
                                    : ""}
                                {this.state.recentBookings.length ?
                                    <div className="recent-booking">
                                        <h1>Your Recent Bookings</h1>
                                        <ul>
                                            {this.state.recentBookings.map((recentBooking, key) =>
                                                <li key={key}>
                                                    <img src={recentBookingIcon} className="img-fluid" alt=""/>
                                                    <div className="text-booking">
                                                        {recentBooking.pol.port_name}<br/>{recentBooking.pod.port_name}
                                                    </div>
                                                    <div className="view">
                                                        <p>USD <b>{recentBooking.total_freight}</b></p>
                                                        <Link
                                                            to={'/booking/' + recentBooking.id + '/view'}>view</Link>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    :
                                    ''
                                }
                                <div className="grow-booking">
                                    <h4> Looking for a lot container booking ?</h4>
                                    <button onClick={this.handleGetQuote}>Get Quote</button>
                                    <span>*Booking in India Only</span>
                                    <img src={bulkBooking} className="img-fluid" alt=""/>
                                </div>
                            </Col>
                            <Col sm="9" lg="9">
                                <div className="routes-found">
                                    <ul>
                                        <li>
                                            <b>{this.state.routes.length}</b> Routes found
                                        </li>
                                        <li>
                                            <img src={routesfoundicon} alt=""/>Transparent Booking Process
                                        </li>
                                        <li>
                                            <img src={routesfoundicon1} alt=""/>No Hidden Charges, You Pay what you see!
                                        </li>
                                        {/*<li className="switch-btn">
                                            <label>
                                                <span> Fastest </span>
                                                <Switch onChange={this.handleFastestChange}
                                                        checked={this.state.checked}/>
                                                <span> Lowest </span>
                                            </label>
                                        </li>*/}
                                        {/*<li className="switch-btn">
                                        <label>
                                            <span>Lowest</span>
                                            <Switch onChange={this.handleLowestChange} checked={this.state.checked}/>
                                        </label>
                                    </li>*/}
                                    </ul>
                                </div>
                                <div className="main-listing-right">
                                    {this.state.routes.length === 0 || this.state.isGetQuote ?
                                        // <div>No rates found for selected port pairs</div>
                                        <Enquiry commodity_type={this.state.commodity_type}
                                                 pol_port={this.state.pol_port}
                                                 pod_port={this.state.pod_port}
                                                 etd_pol={this.state.etd_pol}
                                                 isGetQuote={this.state.isGetQuote}
                                                 container_type={this.state.container_type}
                                                 container_20ft={this.state.container_20ft}
                                                 container_40ft={this.state.container_40ft}
                                                 container_40hc={this.state.container_40hc}
                                        />
                                        :
                                        <table className="table" cellSpacing="0" cellPadding="0">
                                            <thead>
                                            <tr>
                                                <th>
                                                    {this.state.sortTransitTime === null ?
                                                        <i className="fa fa-sort"
                                                           onClick={() => this.filterRates('sortTransitTime', 'transit_time', "sortByDesc")}/> : (this.state.sortTransitTime === "sortBy") ?
                                                            <i className="fa fa-sort-asc"
                                                               onClick={() => this.filterRates('sortTransitTime', 'transit_time', "sortByDesc")}/> :
                                                            <i className="fa fa-sort-desc"
                                                               onClick={() => this.filterRates('sortTransitTime', 'transit_time', "sortBy")}/>

                                                    }
                                                    Time
                                                </th>
                                                <th>
                                                    {this.state.sort_20ft === null ?

                                                        <i className="fa fa-sort"
                                                           onClick={() => this.filterRates('sort_20ft', 'freight_charges_20', "sortByDesc")}/> : (this.state.sort_20ft === "sortBy") ?
                                                            <i className="fa fa-sort-asc"
                                                               onClick={() => this.filterRates('sort_20ft', 'freight_charges_20', "sortByDesc")}/> :
                                                            <i className="fa fa-sort-desc"
                                                               onClick={() => this.filterRates('sort_20ft', 'freight_charges_20', "sortBy")}/>
                                                    }
                                                    20 FT

                                                </th>
                                                {parseInt(this.state.container_type.value) !== REFRIGERATED ?
                                                    <th>
                                                        {this.state.sort_40ft === null ?
                                                            <i className="fa fa-sort"
                                                               onClick={() => this.filterRates('sort_40ft', 'freight_charges_40', "sortByDesc")}/>
                                                            : (this.state.sort_40ft === "sortBy") ?
                                                                <i className="fa fa-sort-asc"
                                                                   onClick={() => this.filterRates('sort_40ft', 'freight_charges_40', "sortByDesc")}/> :
                                                                <i className="fa fa-sort-desc"
                                                                   onClick={() => this.filterRates('sort_40ft', 'freight_charges_40', "sortBy")}/>
                                                        }
                                                        40 FT
                                                    </th> : <th className="changes-new"/>}
                                                <th>
                                                    {this.state.sort_40hc === null ?
                                                        <i className="fa fa-sort"
                                                           onClick={() => this.filterRates('sort_40hc', 'freight_charges_40hc', "sortByDesc")}/> : (this.state.sort_40hc === "sortBy") ?
                                                            <i className="fa fa-sort-asc"
                                                               onClick={() => this.filterRates('sort_40hc', 'freight_charges_40hc', "sortByDesc")}/> :
                                                            <i className="fa fa-sort-desc"
                                                               onClick={() => this.filterRates('sort_40hc', 'freight_charges_40hc', "sortBy")}/>
                                                    }
                                                    40 HC
                                                </th>
                                                <th>
                                                    <Select
                                                        className="search-select sort-by-select"
                                                        value={this.state.selectedFilterOption}
                                                        onChange={(selected) => this.handleChange(selected)}
                                                        options={FilterOptions}
                                                        placeholder={"Sort by"}
                                                        // menuIsOpen={true}
                                                    />
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.routes.map((route, key) =>
                                                <ResultRow queryParams={this.state.queryParams}
                                                           history={this.props.history}
                                                           commodity_type={this.state.commodity_type}
                                                           container_type={this.state.container_type}
                                                           pol_port={this.state.pol_port}
                                                           pod_port={this.state.pod_port}
                                                           etd_pol={this.state.etd_pol}
                                                           route={route}
                                                           key={key}
                                                           row_id={key}
                                                           container_20ft={this.state.container_20ft}
                                                           container_40ft={this.state.container_40ft}
                                                           container_40hc={this.state.container_40hc}/>
                                            )}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }
                <NotificationContainer/>
            </Fragment>
        )
    }

}

const mapStateToProps = ({auth}) => {
    return {auth};
};
export default connect(
    mapStateToProps,
    {}
)(SearchResults);