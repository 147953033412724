import React, {Component, Fragment} from 'react';
import {AvField, AvForm, AvGroup, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {Label, Button, button} from "reactstrap";
import Auth from "../../../util/auth.service";
import {NotificationManager} from 'react-notifications';
import ButtonLoader from "../../../util/ButtonLoader";
import {maxUploadFileSize} from "../../../constants/defaultValues";
import PdfIcon from "../../../assets/images/pdf-icon.png";
import CustomFeedback from "../../../components/formValidations/CustomFeedback";

class Register extends Component {

    state = {
        signUpError: '',
        isButtonLoading: false,
        country_id: '',
        country_list: [],
        selected_country: 96,
        companyType: '1',
        gstFile: null,
        gstFileList: null,
        gstFileError: null,
        panCard: null,
        panCardList: null,
        panCardError: null,
        utilityBills: [],
        utilityBillList: [],
        utilityBillError: null
    };

    componentDidMount() {
        this.setState({country_list: this.props.country_list});
    }

    handleType = (event) => {
        this.setState({companyType: event.target.value});
    }

    uploadUtilityBill = () => {
        var imagefile = document.querySelector('#utility_bill');
        let allowed_extensions = ['pdf', 'jpeg', 'jpg', 'png', 'xlsx', 'doc', 'docx'];
        var utilityBills = this.state.utilityBills;
        let fileError = null;
        for (let fileKey in imagefile.files) {
            let file = imagefile.files[fileKey];
            if (file && file.name) {
                var parts = file.name.split('.');
                if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                    if (file.size <= maxUploadFileSize) {
                        utilityBills.push(file);
                    } else {
                        fileError = "The uploaded file is exceeding the maximum file size of 5 MB."
                    }
                } else {
                    // fileError = "Please select valid file.";
                }
            }
        }
        this.setState({utilityBills, utilityBillError: fileError}, this.handleUtilityListing);
    };

    handleUtilityListing = () => {
        let utilityBillList = [];
        for (let file in this.state.utilityBills) {
            utilityBillList.push(
                <li key={file}>
                    <a href="" target="_blank" rel="noopener noreferrer">
                        <img src={PdfIcon} alt={""}/>{this.state.utilityBills[file].name}
                    </a>
                    <i onClick={() => this.deleteUtilityBill(file)}
                       className="fa fa-times color-changes"/>
                </li>
            );
        }
        this.setState({utilityBillList});
    }

    deleteUtilityBill = (key) => {
        var utilityBills = [];
        for (let doc in this.state.utilityBills) {
            if (doc !== key) {
                utilityBills.push(this.state.utilityBills[doc]);
            }
        }
        this.setState({utilityBills}, this.handleUtilityListing);
    };

    uploadFiles = (fileName, files, fileListing, fileError) => {
        var imagefile = document.querySelector('#' + fileName);
        let fileData = this.state[files];
        let listing = this.state[fileListing];
        if (imagefile.files[0]) {
            var parts = imagefile.files[0].name.split('.');
            let allowed_extensions = ['pdf'];
            if (allowed_extensions.indexOf(parts[parts.length - 1].toLowerCase()) !== -1) {
                if (imagefile.files[0].size <= maxUploadFileSize) {
                    let listing = <li>
                        <a href="" target="_blank" rel="noopener noreferrer">
                            {/*<img src={PdfIcon} alt={""}/>*/}
                            {imagefile.files[0].shortName(30)}
                        </a>
                    </li>;
                    this.setState({
                        [files]: imagefile.files[0] ? imagefile.files[0] : '',
                        [fileListing]: listing,
                        [fileError]: null
                    });
                } else {
                    this.setState({[fileError]: "The uploaded file is exceeding the maximum file size of 5 MB."});
                }
            } else {
                this.setState({[fileError]: "Please select valid file."});
            }
        }
    };

    onSubmit = (event, errors, values) => {
        let utilityBillError = null, panCardError = null, gstFileError = null;
        if (this.state.utilityBills && !this.state.utilityBills.length) {
            utilityBillError = "Please Select Utility Bills.";
            errors.push(utilityBillError);
        }
        if (!this.state.panCard) {
            panCardError = "Please select Pan Card.";
            errors.push(panCardError);
        }
        if (!this.state.gstFile) {
            gstFileError = "Please select Gst File.";
            errors.push(gstFileError);
        }
        this.setState({utilityBillError, panCardError, gstFileError});

        if (errors.length === 0) {
            this.setState({isButtonLoading: true});
            let user = Auth.user;
            let data = {
                ...values,
                pan_card: this.state.panCard,
                gst_file: this.state.gstFile
            };

            if (this.state.utilityBills) {
                for (let file in this.state.utilityBills) {
                    data['utility_bill_' + file] = this.state.utilityBills[file];
                }
            }
            Auth.register(data)
                .then(response => {
                    if (response.data.status === 'success') {
                        NotificationManager.success('Success message', response.data.message);
                    } else {
                        NotificationManager.error('Error message', response.data.message);
                    }
                    this.registerForm.reset();

                    this.setState({
                        isButtonLoading: false,
                        utilityBills: [],
                        utilityBillList: [],
                        panCard: null,
                        panCardList: null,
                        gstFile: null,
                        gstFileList: null
                    });

                })
                .catch(err => {
                    this.setState({isButtonLoading: false});
                });
        }
    };


    render() {

        return (
            <Fragment>
                <AvForm ref={form => this.registerForm = form} onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <Label className="select-leb">Company Type</Label>
                        <AvRadioGroup value="1" inline name="company_type" onClick={this.handleType}
                                      required
                                      errorMessage="Pick one!">
                            <AvRadio customInput label="Shipper"
                                     value="1"/>
                            <AvRadio customInput label="Forwarder"
                                     value="2"/>

                        </AvRadioGroup>
                    </div>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Company Name</Label>
                            <AvField name="company_name" type="text"
                                     value=""
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please enter Company Name'
                                         }
                                     }}/>
                        </AvGroup>
                    </div>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Company Registration No</Label>
                            <AvField name="reg_no" type="text"
                                     value=""
                                     validate={{
                                         required: {
                                             value: this.state.companyType === "1",
                                             errorMessage: 'Please enter Company Registration No.'
                                         }
                                     }}/>
                        </AvGroup>
                    </div>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <label>Registration Type.</label>
                            <AvField type="select" id="reg_type"
                                     name="reg_type"
                                     value=""
                                     validate={{
                                         required: {
                                             value: this.state.companyType === "1",
                                             errorMessage: 'Please enter Registration Type'
                                         },
                                     }}>
                                <option value="">SELECT</option>
                                <option value="IEC No">IEC No</option>
                                <option value="CIN No">CIN No</option>
                                <option value="PAN No">PAN No</option>
                                <option value="Aadhaar No">Aadhaar No</option>
                                <option value="Passport No">Passport No</option>
                            </AvField>
                        </AvGroup>
                    </div>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Contact person first Name</Label>
                            <AvField name="first_name" type="text"
                                     value=""
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please enter First Name'
                                         },
                                         pattern: {
                                             value: "^[A-Za-z]*$",
                                             errorMessage: 'Only letters are allowed'
                                         }
                                     }}/>
                        </AvGroup>
                    </div>


                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Contact person last Name</Label>
                            <AvField name="last_name" type="text"
                                     value=""
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please enter Last Name'
                                         },
                                         pattern: {
                                             value: "^[A-Za-z]*$",
                                             errorMessage: 'Only letters are allowed'
                                         }
                                     }}/>
                        </AvGroup>
                    </div>


                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Email Id of Contact person</Label>
                            <AvField name="email" type="text"
                                     value=""
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please enter Email Id'
                                         },
                                         email: {
                                             value: true,
                                             errorMessage: 'Please enter Valid Email Id'
                                         }
                                     }}/>
                        </AvGroup>
                    </div>


                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Mobile no of Contact person</Label>
                            <AvField name="mobile" type="number"
                                     value=""
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please enter Mobile'
                                         },
                                         minLength: {
                                             value: 10,
                                             errorMessage: 'Please enter valid Mobile Number.'
                                         },
                                         maxLength: {
                                             value: 15,
                                             errorMessage: 'Please enter valid Mobile Number.'
                                         }
                                     }}/>
                        </AvGroup>
                    </div>

                    <div className="form-group">
                        <AvGroup className="error-t-negative">
                            <Label>Country</Label>
                            <AvField type="select" name="country_id"
                                     onChange={this.handleSelectChange}
                                     value={this.state.selected_country}
                                     validate={{
                                         required: {
                                             value: true,
                                             errorMessage: 'Please select Country'
                                         }
                                     }}>
                                <option value="">Select Country</option>
                                {this.props.country_list.map((e, key) => {
                                    return (
                                        <option key={key}
                                                value={e.id}>{e.name}</option>
                                    );
                                })}
                            </AvField>
                        </AvGroup>
                    </div>

                    <div className="col-lg-12 col-sm-12">
                        <div className="upload-2-files hazardous-uplod-file">
                            <div className="upload-btn-wrapper">
                                <button className="btn">
                                    <i className="fa fa-file-alt"/> Select File
                                </button>
                                <p>Please upload GST File.
                                </p>
                                <input type="file" name="gst_file"
                                       id="gst_file"
                                       onChange={() => this.uploadFiles('gst_file', 'gstFile', 'gstFileList', 'gstFileError')}/>
                            </div>
                        </div>
                        <div className="vgm-div-box-right vgm-div-box-hazardous-uplod-file">
                            <ul>
                                {this.state.gstFileList}
                            </ul>
                        </div>
                        <CustomFeedback>{this.state.gstFileError}</CustomFeedback>
                    </div>

                    <div className="col-lg-12 col-sm-12">
                        <div className="upload-2-files hazardous-uplod-file">
                            <div className="upload-btn-wrapper">
                                <button className="btn">
                                    <i className="fa fa-file-alt"/> Select File
                                </button>
                                <p>Please upload PAN Card.
                                </p>
                                <input type="file" name="pan_card"
                                       id="pan_card"
                                       onChange={() => this.uploadFiles('pan_card', 'panCard', 'panCardList', 'panCardError')}/>
                            </div>
                        </div>
                        <div className="vgm-div-box-right vgm-div-box-hazardous-uplod-file">
                            <ul>
                                {this.state.panCardList}
                            </ul>
                        </div>
                        <CustomFeedback>{this.state.panCardError}</CustomFeedback>
                    </div>

                    <div className="col-lg-12 col-sm-12">
                        <div className="upload-2-files hazardous-uplod-file">
                            <div className="upload-btn-wrapper">
                                <button className="btn">
                                    <i className="fa fa-file-alt"/> Select File
                                </button>
                                <p>Please upload Utility Bill any 2(Electricity, Telephone, Premises maintenance bill /
                                    L&L copy, Gas bill).
                                </p>
                                <input type="file" name="utility_bill"
                                       id="utility_bill"
                                       onChange={this.uploadUtilityBill} multiple/>
                            </div>
                        </div>
                        <div className="vgm-div-box-right vgm-div-box-hazardous-uplod-file">
                            <ul>
                                {this.state.utilityBillList}
                            </ul>
                        </div>
                        <CustomFeedback>{this.state.utilityBillError}</CustomFeedback>
                    </div>

                    <div className="login-btn">
                        <Button color="primary">
                            <ButtonLoader isLoading={this.state.isButtonLoading} label="Register"/>
                        </Button>
                    </div>
                </AvForm>
            </Fragment>);

    }
}

export default Register;
