import React, {Component, Fragment} from 'react';
import {AvField, AvForm, AvGroup, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import {Label, Button} from "reactstrap";
import Auth from "../../../util/auth.service";
import Request from "../../../util/api.service";
import {API_URL} from "../../../constants/apiConstants";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {NavLink} from "react-router-dom";
import ButtonLoader from "../../../util/ButtonLoader";

class Register extends Component {

    state = {
        signUpError: '',
        isLoading: true,
        isButtonLoading: false,
        country_id: '',
        country_list: [],
        selected_country: 96
    };

    componentDidMount() {
        Request.get(`${API_URL}/master/country`)
            .then((response) => {
                this.setState({country_list: response.data.data, isLoading: false});
            }).catch(err => err);
    }

    onSubmit = (event, errors, values) => {
        if (errors.length === 0) {
            this.setState({isButtonLoading: true});
            let user = Auth.user;
            let data = {
                ...values
            }
            Auth.register(data)
                .then(response => {
                    console.log(response);
                    if (response.data.status === 'success') {
                        NotificationManager.success('Success message', response.data.message);
                    } else {
                        NotificationManager.error('Error message', response.data.message);
                    }
                    this.registerForm.reset();
                    this.setState({isButtonLoading: false});
                })
                .catch(err => {
                    this.setState({isButtonLoading: false});
                });
        }
    };


    render() {

        return !this.state.isLoading ?
            <Fragment>
                <AvForm ref={form => this.registerForm = form} onSubmit={this.onSubmit}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 col-lg-10 offset-sm-2 offset-lg-1">
                                <div className="logi-register-all">
                                    <div className="register-section login-section">
                                        <h5>Register</h5>
                                        <div className="form-group">

                                            <Label>Company Type</Label>
                                            <AvRadioGroup value="1" inline name="company_type"
                                                          required
                                                          errorMessage="Pick one!">
                                                <AvRadio customInput label="Shipper"
                                                         value="1"/>
                                                <AvRadio customInput label="Forwarder"
                                                         value="2"/>

                                            </AvRadioGroup>
                                        </div>

                                        <div className="form-group">
                                            <AvGroup className="error-t-negative">
                                                <Label>Company Name</Label>
                                                <AvField name="company_name" type="text"
                                                         value=""
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Company Name'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>

                                        <div className="form-group">
                                            <AvGroup className="error-t-negative">
                                                <Label>Company Registration No</Label>
                                                <AvField name="reg_no" type="text"
                                                         value=""
                                                />
                                            </AvGroup>
                                        </div>

                                        <div className="form-group">
                                            <AvGroup className="error-t-negative">
                                                <Label>Contact person first Name</Label>
                                                <AvField name="first_name" type="text"
                                                         value=""
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter First Name'
                                                             },
                                                             pattern: {
                                                                 value: "^[A-Za-z]*$",
                                                                 errorMessage: 'Only letters are allowed'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>


                                        <div className="form-group">
                                            <AvGroup className="error-t-negative">
                                                <Label>Contact person last Name</Label>
                                                <AvField name="last_name" type="text"
                                                         value=""
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Last Name'
                                                             },
                                                             pattern: {
                                                                 value: "^[A-Za-z]*$",
                                                                 errorMessage: 'Only letters are allowed'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>


                                        <div className="form-group">
                                            <AvGroup className="error-t-negative">
                                                <Label>Email Id of Contact person</Label>
                                                <AvField name="email" type="text"
                                                         value=""
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Email Id'
                                                             },
                                                             email: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Valid Email Id'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>


                                        <div className="form-group">
                                            <AvGroup className="error-t-negative">
                                                <Label>Mobile no of Contact person</Label>
                                                <AvField name="mobile" type="number"
                                                         value=""
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please enter Mobile'
                                                             },
                                                             minLength: {
                                                                 value: 10,
                                                                 errorMessage: 'Please enter valid Mobile Number.'
                                                             },
                                                             maxLength: {
                                                                 value: 15,
                                                                 errorMessage: 'Please enter valid Mobile Number.'
                                                             }
                                                         }}/>
                                            </AvGroup>
                                        </div>

                                        <div className="form-group">
                                            <AvGroup className="error-t-negative">
                                                <Label>Country</Label>
                                                <AvField type="select" name="country_id"
                                                         onChange={this.handleSelectChange}
                                                         value={this.state.selected_country}
                                                         validate={{
                                                             required: {
                                                                 value: true,
                                                                 errorMessage: 'Please select Country'
                                                             }
                                                         }}>
                                                    <option value="">Select Country</option>
                                                    {this.state.country_list.map((e, key) => {
                                                        return (
                                                            <option key={key}
                                                                    value={e.id}>{e.name}</option>
                                                        );
                                                    })}
                                                </AvField>
                                            </AvGroup>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <Button color="primary">
                                                <ButtonLoader isLoading={this.state.isButtonLoading} label="Register"/>
                                            </Button>
                                            <NavLink to={`/login`}>
                                                Login
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </AvForm>
                <NotificationContainer/>
            </Fragment> : <Fragment/>

    }
}

export default Register;
