import React, {Component} from 'react';
import {connect} from "react-redux";
import {registerUser, registerUserFailure} from "../../redux/auth/actions";
import fclIcon from "../../assets/images/fcl-ico.png";
import lclIcon from "../../assets/images/lcl-ico.png";
import {Col, Row} from "reactstrap";
import ButtonLoader from "../../util/ButtonLoader";
import Auth from "../../util/auth.service";

const SHIPPER_COMPANY = 1;
const FORWARDER_COMPANY = 2;

class SignupForm extends Component {

    state = {
        company_type: SHIPPER_COMPANY, // 1-shipper, 2-forwarder,
        signUpError: '',
        isLoading: false,
        first_name: '',
        last_name: '',
        company_name: '',
        email: '',
        country_id: '',
        reg_no: '',
        mobile: ''
    };

    toggleCompanyType = () => {
        let {company_type} = this.state;
        company_type = company_type === FORWARDER_COMPANY ? SHIPPER_COMPANY : FORWARDER_COMPANY;
        this.setState({company_type, reg_no: ''});
    };

    handleInputChange = (event) => {
        let value = (event.target.name === 'reg_no') ? event.target.value.toUpperCase() : event.target.value;
        this.setState({[event.target.name]: value})
    };

    handleSubmit = () => {
        if (this.validateSubmit()) {
            this.setState({isLoading: true});
            Auth.register(this.state)
                .then(response => {
                    let signUpError;
                    if (response.data.status === 'success') {
                        signUpError = 'Registration successful, please check your email.';
                    } else {
                        signUpError = response.data.message;
                    }
                    this.setState({isLoading: false, signUpError});
                })
                .catch(err => {
                    this.setState({isLoading: false});
                });
        }
    };

    validateSubmit = () => {
        if (!this.state.first_name) {
            this.setState({signUpError: 'Please provide your first name'});
            return false;
        } else if (!this.state.last_name) {
            this.setState({signUpError: 'Please provide your last name'});
            return false;
        } else if (!this.state.email) {
            this.setState({signUpError: 'Please provide your email'});
            return false;
        } else if (!this.state.company_name) {
            this.setState({signUpError: 'Please provide your company name'});
            return false;
        } else if (!this.state.country_id) {
            this.setState({signUpError: 'Please select your country'});
            return false;
        } else if (this.state.company_type === SHIPPER_COMPANY && !this.state.reg_no) {
            this.setState({signUpError: 'Please provide company registration number'});
            return false;
        } else if (!this.state.mobile) {
            this.setState({signUpError: 'Please provide Phone No'});
            return false;
        } else {
            this.setState({signUpError: ''});
            return true;
        }
    };

    render() {
        return (
            <Row>
                <div className="auth-error">&nbsp;&nbsp; {this.state.signUpError} &nbsp;&nbsp;</div>
                <p style={{marginBottom: '2px'}}>I'm a</p>
                <Col lg="12" sm="12" xs="12">
                <div className="select-tab-login">
                <ul>
                    <li className={this.state.company_type === SHIPPER_COMPANY ? "active-tab" : ""}
                        onClick={this.toggleCompanyType}>
                        <span><img src={fclIcon} alt="Shipper"/>Shipper</span>
                    </li>
                    <li className={this.state.company_type === FORWARDER_COMPANY ? "active-tab forwarder-tb" : "forwarder-tb"}
                        onClick={this.toggleCompanyType}>
                        <span><img src={lclIcon} alt="Forwarder"/>Forwarder</span>
                    </li>
                </ul>
                </div>
                </Col>
                <Col lg="6" sm="6" xs="6">
                    <span className="input input--nao">
                        <input className="input__field input__field--nao" type="text" name="first_name"
                               onChange={this.handleInputChange}/>
                        <label className="input__label input__label--nao" htmlFor="input-1">
                            <span className="input__label-content input__label-content--nao">
                                First Name*
                            </span>
                        </label>
                        <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                             preserveAspectRatio="none">
                            <path
                                d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                        </svg>
                    </span>
                </Col>
                <Col lg="6" sm="6" xs="6">
                    <span className="input input--nao">
                        <input className="input__field input__field--nao" type="text" name="last_name"
                               onChange={this.handleInputChange}/>
                        <label className="input__label input__label--nao" htmlFor="input-1">
                            <span className="input__label-content input__label-content--nao">
                                Last Name*
                            </span>
                        </label>
                        <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                             preserveAspectRatio="none">
                            <path
                                d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                        </svg>
                    </span>
                </Col>
                <Col lg="12" sm="12">
                    <span className="input input--nao">
                        <input className="input__field input__field--nao" type="text" name="email"
                               onChange={this.handleInputChange}/>
                        <label className="input__label input__label--nao" htmlFor="input-1">
                            <span className="input__label-content input__label-content--nao">
                                Email*
                            </span>
                        </label>
                        <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                             preserveAspectRatio="none">
                            <path
                                d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                        </svg>
                    </span>
                </Col>

                <Col lg="12" sm="12" xs="12">
                        <span className="input input--nao">
                            <input className="input__field input__field--nao" type="text" name="mobile"
                                   value={this.state.mobile}
                                   onChange={this.handleInputChange}/>
                            <label className="input__label input__label--nao" htmlFor="input-1">
                                <span className="input__label-content input__label-content--nao">
                                    Phone No*
                                </span>
                            </label>
                            <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                 preserveAspectRatio="none">
                                <path
                                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                            </svg>
                        </span>
                </Col>

                <Col lg="6" sm="6" xs="12">
                    <span className="input input--nao">
                        <input className="input__field input__field--nao" type="text" name="company_name"
                               onChange={this.handleInputChange}/>
                        <label className="input__label input__label--nao" htmlFor="input-1">
                            <span className="input__label-content input__label-content--nao">
                                Company Name*
                            </span>
                        </label>
                        <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                             preserveAspectRatio="none">
                            <path
                                d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                        </svg>
                    </span>
                </Col>
                <Col lg="6" sm="6" xs="12">
                    <span className="input input--nao">
                        <select className="input__field input__field--nao" name="country_id"
                                onChange={this.handleInputChange}>
                            <option value="">Select Country</option>
                            {this.props.countries.map(country => <option value={country.id}
                                                                         key={country.id}>{country.name}</option>)}
                        </select>
                        <label className="input__label input__label--nao" htmlFor="input-1">
                            <span className="input__label-content input__label-content--nao">
                                Country
                            </span>
                        </label>
                        <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                             preserveAspectRatio="none">
                            <path
                                d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                        </svg>
                    </span>
                </Col>
                <Col lg="12" sm="12" xs="12">
                    {this.state.company_type === SHIPPER_COMPANY ?
                        <span className="input input--nao">
                            <input className="input__field input__field--nao" type="text" name="reg_no"
                                   value={this.state.reg_no}
                                   onChange={this.handleInputChange}
                                   disabled={this.state.company_type === FORWARDER_COMPANY}/>
                            <label className="input__label input__label--nao" htmlFor="input-1">
                                <span className="input__label-content input__label-content--nao">
                                    Company Registration No*
                                </span>
                            </label>
                            <svg className="graphic graphic--nao" width="300%" height="100%" viewBox="0 0 1200 60"
                                 preserveAspectRatio="none">
                                <path
                                    d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"/>
                            </svg>
                        </span>
                        :
                        <span className="input input--nao"/>}
                </Col>

                <Col lg="12" sm="12">
                    <div className="submit-btn sign-up-btn">
                        <button onClick={this.handleSubmit}>
                            <ButtonLoader isLoading={this.state.isLoading} label="Submit"/>
                        </button>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({auth}) => {
    return {auth};
};

export default connect(
    mapStateToProps,
    {registerUser, registerUserFailure}
)(SignupForm);
// export default LoginForm;