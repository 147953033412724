import {ADD_PORTS, FETCH_PORTS} from 'constants/actionTypes';

const INIT_STATE = {
    is_loading: false,
    loaded: false,
    list: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_PORTS:
            return {...state, is_loading: true};
        case ADD_PORTS:
            return {...state, loaded: true, list: action.payload};
        default:
            return {...state};
    }
}
